import { PreparedData } from "@pages/project-details/project-data-management/prepared-data/prepared-data-types";
import { RegistrationState } from "@faro-lotv/service-wires";
import { isSdbBackgroundTaskOngoing } from "@utils/sdb-background-tasks-utils";

/**
 * @returns Whether the PreparedData entity is processing or processed
 *
 * @param preparedData PreparedData entity
 */
export function isPreparedDataProcessingOrProcessed(
  preparedData: PreparedData
): boolean {
  return (
    isPreparedDataProcessing(preparedData) ||
    isPreparedDataProcessed(preparedData)
  );
}

/**
 * @returns true iff the PreparedData entity is processing
 *
 * @param preparedData PreparedData entity
 */
export function isPreparedDataProcessing(preparedData: PreparedData): boolean {
  // Early exit with false if the task property is not defined or if is not an ongoing task
  if (!preparedData.task || !isSdbBackgroundTaskOngoing(preparedData.task)) {
    return false;
  }

  return (
    preparedData.state === RegistrationState.cloudRegistrationStarted ||
    preparedData.state === RegistrationState.started
  );
}

/**
 * @returns true iff the PreparedData entity has already been processed
 *
 * @param preparedData PreparedData entity
 */
export function isPreparedDataProcessed(preparedData: PreparedData): boolean {
  return preparedData.state === RegistrationState.registered;
}
