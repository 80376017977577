import { useMemo } from "react";
import { Box } from "@mui/material";
import { selectProjectLoadingState } from "@faro-lotv/project-source";
import { RegistrationState } from "@faro-lotv/service-wires";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { FaroButton } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { getInspectAndPublishToolUrl } from "@pages/project-details/project-data-management/data-management-utils";
import { PrepareDataButton } from "@pages/project-details/project-data-management/prepare-data-button";
import { preparedDataSelector } from "@pages/project-details/project-data-management/prepared-data/prepared-data-selectors";
import { uploadedDataSelector } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import { publishedDataSelector } from "@pages/project-details/project-data-management/published-data/published-data-selectors";
import { PublishedDataTable } from "@pages/project-details/project-data-management/published-data/published-data-table";
import { UploadedDataTable } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-table";
import {
  fetchingStatusAllRegistrationRevisionsSelector,
  fetchingStatusCaptureTreeForMainRevisionSelector,
  hasFetchedAllRegistrationRevisionsSelector,
  hasFetchedCaptureTreeForMainRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { sphereColors } from "@styles/common-colors";
import { OPEN_PROJECT_TARGET_ATTRIBUTE } from "@utils/project-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Renders the uploaded data section of the data management page */
export function UploadedData(): JSX.Element {
  // ##### Uploaded data ##### //

  const uploadedData = useAppSelector(uploadedDataSelector);
  const publishedData = useAppSelector(publishedDataSelector);

  const fetchingStatusUploaded = useAppSelector(fetchingStatusCaptureTreeForMainRevisionSelector);
  const hasFetchedCaptureTree = useAppSelector(hasFetchedCaptureTreeForMainRevisionSelector);
  const fetchingStatusIElements = useAppSelector(selectProjectLoadingState);

  // ##### Prepared data ##### //

  const preparedData = useAppSelector(preparedDataSelector);
  const { trackEvent } = useTrackEvent();

  const fetchingStatusPrepared = useAppSelector(fetchingStatusAllRegistrationRevisionsSelector);
  const hasFetchedAllRegistrationRevisions = useAppSelector(hasFetchedAllRegistrationRevisionsSelector);

  // TODO: It's currently assumed that preparedData only holds one object after the upcoming changes to the
  // Staging Area: https://faro01.atlassian.net/browse/TF-1693
  // If that turns out to be true, the array should be changed to a single object.
  // IF that turns out to be false, the code needs to be adjusted.
  const regState = preparedData.length ? preparedData[0].state : undefined;
  const isInspectEnabled = regState === RegistrationState.registered || regState === RegistrationState.merged;
  // TODO: Remove OpenDataPreparationButton once it's no longer used elsewhere after the upcoming changes to the
  // Staging Area: https://faro01.atlassian.net/browse/TF-1693
  const projectId = preparedData.length ? preparedData[0].projectId : "";
  const registrationId = preparedData.length ? preparedData[0].id : "";

  const inspectTooltip: string = useMemo(() => {
    if (regState === RegistrationState.merged) {
      return "Inspect the published scans.";
    } else if (regState === RegistrationState.registered) {
      return "Inspect and publish the registered scans.";
    } else if (regState === RegistrationState.started || regState === RegistrationState.cloudRegistrationStarted) {
      return "The registration of the scans is in progress. Please wait...";
    } else if (regState === RegistrationState.canceled) {
      return "The registration of the scans was cancelled. Please try again...";
    } else {
      return "The registration of the scans has not been triggered yet.";
    }
  }, [regState]);

  // ##### Common checks ##### //

  const isFetchingForTheFirstTime = useMemo(() => {
    return (
      !hasFetchedCaptureTree &&
      !hasFetchedAllRegistrationRevisions &&
      (fetchingStatusUploaded === FetchingStatus.pending ||
        fetchingStatusPrepared === FetchingStatus.pending ||
        fetchingStatusIElements === "loading")
    );
  }, [
    fetchingStatusUploaded,
    fetchingStatusPrepared,
    fetchingStatusIElements,
    hasFetchedCaptureTree,
    hasFetchedAllRegistrationRevisions,
  ]);

  const shouldShowEmptyPage = useMemo(() => {
    return uploadedData.length === 0 && preparedData.length === 0 && !isFetchingForTheFirstTime;
  }, [uploadedData.length, preparedData.length, isFetchingForTheFirstTime]);

  const hasFailedToFetchUploadedData = useMemo(() => {
    return fetchingStatusUploaded === FetchingStatus.rejected || fetchingStatusIElements === "failed";
  }, [fetchingStatusUploaded, fetchingStatusIElements]);

  const hasFailedToFetchPreparedData = useMemo(() => {
    return fetchingStatusPrepared === FetchingStatus.rejected;
  }, [fetchingStatusPrepared]);

  // ##### Empty page or error page ##### //

  const emptyPageTitle: string = hasFailedToFetchUploadedData || hasFailedToFetchPreparedData ? "Error" : "No uploaded data";

  let emptyPageSubtitle: string;
  if (hasFailedToFetchPreparedData) {
    emptyPageSubtitle = "Failed to fetch the prepared data. Please reload the page and try again.";
  } else if (hasFailedToFetchUploadedData) {
    emptyPageSubtitle = "Failed to fetch the uploaded data. Please reload the page and try again.";
  } else {
    emptyPageSubtitle = "No uploaded data to show for this project.";
  }

  const emptyPageIcon = hasFailedToFetchUploadedData || hasFailedToFetchPreparedData ? FailedIcon : NoDataIcon;

  return (
    <div>
      <div data-testid="inspect-button-container" style={{ float: "right", marginTop: "14px" }}>
        <SphereTooltip title={inspectTooltip}>
          <FaroButton
            isDisabled={!isInspectEnabled}
            to={getInspectAndPublishToolUrl(projectId, registrationId)}
            target={OPEN_PROJECT_TARGET_ATTRIBUTE}
            onClick={() => {
              trackEvent({
                name: DataManagementEvents.openDataManagementInspectPublish,
                props: {
                  projectId,
                  registrationId,
                },
              });
            }}
          >
            Inspect
          </FaroButton>
        </SphereTooltip>
      </div>
      <Box
        data-testid="uploaded-data-content"
        sx={{
          marginTop: "14px",
          border: `1px solid ${sphereColors.gray200}`,
          borderRadius: 0,
          display: "inline-block",
          width: "100%",
        }}
      >
        {shouldShowEmptyPage ? (
          <EmptyPage title={emptyPageTitle} subtitle={emptyPageSubtitle} icon={emptyPageIcon} />
        ) : (
          <Box
            sx={{
              background: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: `1px solid ${sphereColors.gray200}`,
                paddingX: "30px",
                fontWeight: "bold",
                height: "64px",
              }}
            >
              Uploaded Data
              <PrepareDataButton />
            </Box>
            <Box
              sx={{
                paddingX: "30px",
                paddingBottom: "30px",
              }}
            >
              <UploadedDataTable uploadedData={uploadedData} isFetchingForTheFirstTime={isFetchingForTheFirstTime} />
            </Box>

            {0 < publishedData.length && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: `1px solid ${sphereColors.gray200}`,
                    paddingX: "30px",
                    fontWeight: "bold",
                    height: "64px",
                  }}
                >
                  Published Data
                </Box>
                <Box
                  sx={{
                    paddingX: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <PublishedDataTable
                    publishedData={publishedData}
                    isFetchingForTheFirstTime={isFetchingForTheFirstTime}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
}
