import { CaptureTreeEntity } from "@faro-lotv/service-wires";

/** Headers for the uploaded data table */
export enum UploadedDataTableHeaders {
  /** Entity name */
  name = "Name",

  /** Path to the cluster where the entity is located */
  clusterPath = "Cluster path",

  /** User who created the entity */
  createdBy = "Created by",

  /** The date time when the entity was created */
  createdAt = "Created at",

  /** Type of device used to generate the entity */
  deviceType = "Device",

  /** Where the user will see the spinner loading and the message */
  actions = "Actions",
}

/** Possible device types used to generate the an UploadedData entity of type scan */
export enum DeviceType {
  blink = "Blink",
  focus = "Focus",
  orbis = "Orbis",
  unknown = "Unknown",
}

/** Uploaded ELS scan */
export interface UploadedElsScan extends CaptureTreeEntity {
  /** Path to the cluster where the entity is located */
  clusterPath: string;

  /** Whether the scan is still processing */
  isProcessing: boolean;
}

/** All possible uploaded data types */
export type UploadedData = UploadedElsScan;
