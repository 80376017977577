/** Used to calculate file size in byte, megaByte and gigaByte */
export const FILE_SIZE_MULTIPLIER = 1024;

/* eslint-disable @typescript-eslint/no-magic-numbers */
const MAGIC_FILE_HEADERS: Record<string, number[]> = {
  // https://www.sqlite.org/fileformat.html#magic_header_string
  gls: [
    0x53, 0x51, 0x4c, 0x69, 0x74, 0x65, 0x20, 0x66, 0x6f, 0x72, 0x6d, 0x61,
    0x74, 0x20, 0x33, 0x00,
  ],
  // Any other binary file types could be added in the future.
  // https://en.wikipedia.org/wiki/List_of_file_signatures
  // gif: [0x47, 0x49, 0x46, 0x38],
  // jpeg: [0xff, 0xd8, 0xff],
  // jpg: [0xff, 0xd8, 0xff],
  // png: [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a],
};
/* eslint-enable @typescript-eslint/no-magic-numbers */

/**
 * Check the extension of the file if it's valid or not
 *
 * @param {file} file The file to check
 * @returns true if the file has a supported format
 */
export function isValidFileExtension(
  file: File,
  validExtensions: string[]
): boolean {
  const extension = getFileExtension(file.name)?.toLowerCase();
  return !!extension && validExtensions.includes(extension);
}

/** Returns mega bytes after converting from bytes */
export function bytesToMegaBytes(bytes: number): string {
  const megaBytes = bytes / (FILE_SIZE_MULTIPLIER * FILE_SIZE_MULTIPLIER);
  return megaBytes.toFixed(2);
}

/**
 * Sort files by name
 *
 * @param files The array of file
 * @returns Sorted array of the files
 */
export function sortFiles(files: File[]): File[] {
  return files.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
}

// Contains the supported image file extensions
const IMAGE_FILE_TYPES = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "webp",
  "avif",
  "svg",
  "apng",
];

/**
 * Check if the provided file extension is an image file type.
 *
 * @param fileExtension - The file extension to check.
 * @returns boolean - true if the file is an image, false otherwise.
 */
export function isImageFileType(fileExtension: string): boolean {
  return IMAGE_FILE_TYPES.includes(fileExtension.toLowerCase());
}

/**
 * Extracts the file extension from a given file name.
 *
 * @param fileName - The full name of the file (including the extension).
 * @returns string - The file extension (e.g., 'jpg', 'png'), or an empty string if there is no extension.
 */
export function getFileExtension(fileName: string): string {
  const trimmedFileName = fileName.trim();

  if (
    trimmedFileName.startsWith(".") &&
    trimmedFileName.indexOf(".", 1) === -1
  ) {
    return "";
  }

  const fileNameParts = trimmedFileName.split(".");
  return fileNameParts.length > 1
    ? (fileNameParts.pop() ?? "").toLowerCase()
    : "";
}

/**
 * @param byteArray An array of bytes, like [0x00, 0xFF].
 * @returns A hex string in lower case, like "00ff".
 */
export function bytesToHex(byteArray: number[] | Uint8Array): string {
  return (
    Array.from(byteArray)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("")
  );
}

/**
 * For some known file extensions, checks if the file has the expected header.
 * For other file extensions, this function is a NOP.
 * @throws {Error} if the header is invalid or could not be read.
 */
export async function checkMagicFileHeader(file: File): Promise<void> {
  const extension = getFileExtension(file.name)?.toLowerCase();
  const expectedHeader: number[] | undefined = MAGIC_FILE_HEADERS[extension];
  if (!extension || !expectedHeader) {
    return;
  }

  const numBytes = expectedHeader.length;
  const buffer = await file.slice(0, numBytes).arrayBuffer();
  const actualHeaderHex = bytesToHex(new Uint8Array(buffer));
  const expectedHeaderHex = bytesToHex(expectedHeader);

  if (actualHeaderHex !== expectedHeaderHex) {
    throw new Error("Invalid file contents.");
  }
}
