import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Stack,
  Box,
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog";
import { PropsWithChildren, useMemo } from "react";
import { FARO_BUTTON_HEIGHT, FaroButton } from "@components/common/faro-button";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { FaroClickableIcon } from "@components/common/faro-clickable-icon";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { FaroButtonProps } from "@faro-lotv/flat-ui";
import { addTransparency } from "@utils/ui-utils";

export interface FaroDialogProps
  extends Pick<DialogProps, "open" | "maxWidth" | "fullWidth" | "sx"> {
  /** Title of the dialog */
  title: string;

  /** The icon in the title */
  iconInTitle?: React.ReactNode;

  /** Setting to true will disable the confirm button */
  isConfirmDisabled?: boolean;

  /** Setting to true will show a loader on the confirm button */
  isConfirmLoading?: boolean;

  /** Setting to true will hide the confirm and close buttons */
  shouldHideActions?: boolean;

  /** If true, the close button in the top right corner will always be shown */
  shouldForceShowCloseButton?: boolean;

  /** Text to display on the confirm button */
  confirmText?: string;

  /** Text to display on the close button */
  closeText?: string;

  /** Text do display on the left side button */
  leftSideButtonText?: string;

  /** Called when the user click Confirm. If not defined the confirm button will not be shown */
  onConfirm?(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ): void;

  /**
   * Called when the user clicks the cancel button in the bottom of the dialog. It will be called before onClose,
   * but won't be called when clicking on the X button in the top right (unlike the onClose function)
   */
  onCancelFeedback?(): void;

  /**
   * Called when the user click Closed button. It will close the modal without implementing anymore logic.
   * This button is used in two ways:
   * 1. behave like cancel button when shown with confirm button
   * 2. behave like close button when shown alone (like in a success dialog/message)
   */
  onClose?(event?: React.MouseEvent): void;

  /** Called when the user clicks the left side button. If not defined the left side button will not be shown */
  onLeftSideButtonClick?(): void;

  /**
   * Based on design the close button should always be visible.
   * Only success messages can hide the close button as it is just a confirmation.
   * Therefore, if it is true, we hide the close button
   */
  isSuccessMessage?: boolean;

  /**
   * Optional property to change the color of the confirm button.
   * Default blue.
   */
  confirmButtonColor?: "blue" | "red";

  /**
   * Optional bottom left side component to show on the left side of the actions section.
   * The left side button has higher priority than this component, so if `onLeftSideButtonClick` is
   * defined the left side button will be shown instead of this component.
   */
  bottomLeftSideComponent?: React.ReactNode;

  /**
   * An object containing custom class names for specific parts of the dialog component,
   * such as the container or the root element.
   */
  customClasses?: Partial<Record<"container" | "root", string>>;

  /**
   * Hide the backdrop behind the dialog, removing its opacity effect.
   */
  isHideBackdrop?: boolean;

  /** Custom styling for confirm button */
  customConfirmButtonSx?: FaroButtonProps["sx"];
}

/** Padding outside of the modal */
export const SPACE_OUTSIDE_OF_MODAL = "32px";

/** Space between the elements in the modal. Last elements of the modal shouldn't have the padding */
export const SPACE_ELEMENTS_OF_MODAL = "24px";

/**
 * @returns a dialog to ask the user to confirm or cancel an action
 */
export function FaroDialog({
  title,
  iconInTitle,
  children,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
  open,
  onConfirm,
  isConfirmDisabled = false,
  isConfirmLoading = false,
  shouldHideActions = false,
  shouldForceShowCloseButton = false,
  confirmText = "Confirm",
  closeText = "Cancel",
  leftSideButtonText = "Back",
  onClose,
  onCancelFeedback,
  onLeftSideButtonClick,
  isSuccessMessage = false,
  bottomLeftSideComponent,
  confirmButtonColor = "blue",
  maxWidth,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
  fullWidth,
  customClasses,
  isHideBackdrop = false,
  sx,
  customConfirmButtonSx,
}: PropsWithChildren<FaroDialogProps>): JSX.Element {
  /** Properties to override the confirm button */
  const confirmButtonSx: FaroButtonProps["sx"] = useMemo(() => {
    if (confirmButtonColor === "red") {
      return {
        backgroundColor: sphereColors.red600,
        borderColor: sphereColors.red700,
        "&:hover": {
          backgroundColor: sphereColors.red700,
          borderColor: sphereColors.red700,
        },
        "&:active": {
          backgroundColor: sphereColors.red800,
          borderColor: sphereColors.red800,
        },
        "&.Mui-disabled": {
          borderColor: addTransparency({
            color: sphereColors.red600,
            alpha: EDecimalToHex.sixteen,
          }),
          backgroundColor: addTransparency({
            color: sphereColors.red600,
            alpha: EDecimalToHex.hundredFortyFour,
          }),
        },
      };
    }
    return {};
  }, [confirmButtonColor]);

  return (
    <Dialog
      disableRestoreFocus
      hideBackdrop={isHideBackdrop}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={{ ...sx }}
      PaperProps={{ sx: { padding: SPACE_OUTSIDE_OF_MODAL } }}
      classes={{
        ...customClasses,
      }}
    >
      <DialogTitle
        component="h3"
        sx={{
          fontWeight: "600",
          padding: 0,
          pb: SPACE_ELEMENTS_OF_MODAL,
          letterSpacing: "normal",
          color: sphereColors.gray800,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {iconInTitle}
            <span>{title}</span>
          </Stack>
          {/* Only show the close icon if the actions where hidden */}
          {(shouldForceShowCloseButton || shouldHideActions) && (
            <FaroClickableIcon
              component={CloseIcon}
              onClick={onClose}
              iconSize="20px"
            />
          )}
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "0px",
          marginBottom: shouldHideActions ? "10px" : "0px",
          overflowY: "auto",
        }}
      >
        {children}
      </DialogContent>

      {!shouldHideActions && (
        <DialogActions
          sx={{
            padding: "0px",
            pt: SPACE_OUTSIDE_OF_MODAL,
            // Select without the first button incase of multiple buttons
            "&.MuiDialogActions-root>:not(:first-of-type)": {
              ml: "12px",
            },
          }}
        >
          {(onLeftSideButtonClick || bottomLeftSideComponent) && (
            <>
              {onLeftSideButtonClick ? (
                <Button
                  variant="outlined"
                  onClick={onLeftSideButtonClick}
                  sx={{ textTransform: "none" }}
                >
                  {leftSideButtonText}
                </Button>
              ) : (
                <Box component="div">{bottomLeftSideComponent}</Box>
              )}
              {/* line below makes sure button is left most side */}
              <div style={{ flex: "1 0 0" }} />
            </>
          )}
          {!isSuccessMessage && (
            <Button
              disableFocusRipple={true}
              disableRipple={true}
              disableElevation={true}
              disableTouchRipple={true}
              variant="text"
              disabled={isConfirmLoading}
              onClick={(event) => {
                onCancelFeedback?.();
                onClose?.(event);
              }}
              sx={{
                textTransform: "none",
                minWidth: "89px",
                height: FARO_BUTTON_HEIGHT,
                py: "6px",
                px: "16px",
                color: sphereColors.blue500,
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: addTransparency({
                    color: sphereColors.gray500,
                    alpha: EDecimalToHex.thirtyEight,
                  }),
                },
                "&:active": {
                  backgroundColor: addTransparency({
                    color: sphereColors.gray500,
                    alpha: EDecimalToHex.sixtyFour,
                  }),
                },
              }}
            >
              {closeText}
            </Button>
          )}

          {onConfirm && (
            <FaroButton
              onClick={onConfirm}
              isDisabled={isConfirmDisabled}
              isLoading={isConfirmLoading}
              sx={{
                textTransform: "none",
                ...confirmButtonSx,
                ...customConfirmButtonSx,
              }}
              loadingIndicatorColor={
                confirmButtonColor === "red" ? sphereColors.red500 : undefined
              }
              loadingTrackColor={
                confirmButtonColor === "red" ? sphereColors.red100 : undefined
              }
            >
              {confirmText}
            </FaroButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
