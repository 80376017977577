import { isCloudRegistrationTask } from "@custom-types/sdb-background-tasks-type-guards";
import { PreparedData } from "@pages/project-details/project-data-management/prepared-data/prepared-data-types";
import { createSelector } from "@reduxjs/toolkit";
import { allRegistrationRevisionsSelector } from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { RootState } from "@store/store-helper";
import {
  isPreparedDataProcessing,
  isPreparedDataProcessingOrProcessed,
} from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";

/**
 * @returns All PreparedData entities
 */
export const preparedDataSelector: (state: RootState) => PreparedData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const registrations = allRegistrationRevisionsSelector(state);
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const cloudRegistrationTasks = backgroundTasks.filter(
        isCloudRegistrationTask
      );

      const entities: PreparedData[] = registrations.map((registration) => {
        const task = cloudRegistrationTasks.find(
          (cloudRegistrationTask) =>
            cloudRegistrationTask.context?.elementId === registration.id
        );

        return {
          ...registration,
          task,
        };
      });

      return entities
        .filter(isPreparedDataProcessingOrProcessed)
        .sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt).getTime();
          const bCreatedAt = new Date(b.createdAt).getTime();
          return bCreatedAt - aCreatedAt;
        });
    }
  );

/** @returns whether there is any prepared data entity still processing */
export const hasPreparedDataProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const preparedDataEntities = preparedDataSelector(state);
      return preparedDataEntities.some(isPreparedDataProcessing);
    }
  );
