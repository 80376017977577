import NoAnnotationsIcon from "@assets/icons/new/no_annotations.svg?react";
import { TeamsTable } from "@components/table/teams/teams-table";
import { TeamsDetails } from "@pages/members/teams/teams-details";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import {
  fetchingStatusTeamsSelector,
  teamsSelector,
} from "@store/teams/teams-selector";
import { fetchTeams } from "@store/teams/teams-slice-thunk";
import { useEffect, useMemo } from "react";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { searchSelector } from "@store/ui/ui-selector";
import { useDebounce } from "@utils/time-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SearchbarEvents } from "@utils/track-event/track-event-list";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { TeamsPageBulkActions } from "@pages/members/teams/teams-page-bulk-actions";
import { NotFoundPage } from "@pages/not-found-page";

/**
 * The component that will render the kpi details and the table for the user groups
 */
export function Teams(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const dispatch = useAppDispatch();
  const teams = useAppSelector(teamsSelector);
  const hasFetchedTeams = useAppSelector(fetchingStatusTeamsSelector);
  const { searchText } = useAppSelector(searchSelector);
  const { trackEvent } = useTrackEvent();

  // Fetch teams
  useEffect(() => {
    if (companyId) {
      dispatch(fetchTeams({ coreApiClient, companyId }));
    }
  }, [companyId, coreApiClient, dispatch]);

  // Constructing the empty page content if should show
  const emptyPageContent = useMemo(() => {
    if (teams.length || hasFetchedTeams === FetchingStatus.pending) {
      return null;
    }

    if (hasFetchedTeams === FetchingStatus.rejected) {
      return (
        <EmptyPage
          title="Error"
          subtitle={`Failed to fetch the ${TEAMS_DISPLAY_NAME}. Please reload the page and try again.`}
          icon={FailedIcon}
        />
      );
    }

    return (
      <EmptyPage
        title={`No ${TEAMS_DISPLAY_NAME}`}
        subtitle={`There are no ${TEAMS_DISPLAY_NAME} to show.`}
        icon={NoAnnotationsIcon}
      />
    );
  }, [hasFetchedTeams, teams.length]);

  /** Debounce interval in milliseconds */
  const DEBOUNCE_TIME = 300;

  // Uses useDebounce hook to react to changes on the search input text.
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_TIME);

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        name: SearchbarEvents.searchForTeam,
      });
    }
  }, [debouncedSearchText, trackEvent]);

  if (!companyId) {
    return <NotFoundPage />;
  }

  const buttonComponents = TeamsPageBulkActions({
    companyId,
    teams,
  });

  return (
    <>
      <TeamsDetails />

      {emptyPageContent ?? (
        <TeamsTable
          teams={teams}
          isLoading={hasFetchedTeams === FetchingStatus.pending}
          requiredColumns={[
            TeamsHeaders.name,
            TeamsHeaders.users,
            TeamsHeaders.creationDate,
            TeamsHeaders.options,
          ]}
          bulkActionButtons={buttonComponents}
        />
      )}
    </>
  );
}
