import {
  NonStringOption,
  SelectedOption,
} from "@components/common/members-autocomplete/members-autocomplete-types";
import { useMemo, useState } from "react";
import {
  getOptionLabel,
  getOptionValue,
  getUpdatedSelectedOptions,
} from "@components/common/members-autocomplete/members-autocomplete-utils";
import {
  OnChangeAutocompleteProps,
  OnInputChangeAutocompleteProps,
  Props,
  ReturnProps,
} from "@hooks/autocomplete/use-members-autocomplete-types";
import { isString } from "lodash";

/**
 * Custom hook that handles the logic of the MembersAutocomplete component.
 * It handles the selected options, the input value, the change event, blur event,
 * input change event and filters the options to show in the dropdown.
 */
export function useMembersAutocomplete({
  initialValue,
  options,
  validateNewOption,
  handleChange,
  onInputChange,
}: Props): ReturnProps {
  const [selectedOptions, setSelectedOptions] =
    useState<SelectedOption[]>(initialValue);

  const [inputValue, setInputValue] = useState<string>("");

  /** The values of the selected options in lowercase. This means that the ID of options from dropdown or the new email added  */
  const selectedValues: string[] = useMemo(() => {
    return selectedOptions.map((option) => {
      return getOptionValue(option).toLowerCase();
    });
  }, [selectedOptions]);

  /**
   * Returns all the options that are preselected which means they are  non deletable.
   * This is done in useMemo to prevent iterating over the options array too often.
   */
  const preselectedOptions: NonStringOption[] = useMemo(() => {
    return options.filter((option) => option.isPreselected);
  }, [options]);

  function onAutocompleteChange({
    value,
    reason,
  }: OnChangeAutocompleteProps): void {
    const validSelectedOptions: SelectedOption[] = value.filter((option) => {
      if (typeof option === "string") {
        return validateNewOption(option);
      }
      return true;
    });
    const validSelectedOptionValues = validSelectedOptions.map(getOptionValue);

    preselectedOptions.forEach((option) => {
      if (!validSelectedOptionValues.includes(getOptionValue(option))) {
        validSelectedOptions.push(option);
        validSelectedOptionValues.push(getOptionValue(option));
      }
    });

    setSelectedOptions(validSelectedOptions);
    handleChange(validSelectedOptionValues, reason);
  }

  function onAutocompleteBlur(): void {
    if (!inputValue) {
      return;
    }
    if (selectedValues.includes(inputValue)) {
      return;
    }
    if (validateNewOption(inputValue)) {
      const newOptions = [...selectedOptions, inputValue];
      setSelectedOptions(newOptions);
      handleChange(newOptions.map((option) => getOptionValue(option)));
      setInputValue("");
    }
  }

  function onAutocompleteInputChange({
    value,
  }: OnInputChangeAutocompleteProps): void {
    const updatedSelectedOptions = getUpdatedSelectedOptions(
      selectedOptions,
      value
    );

    if (updatedSelectedOptions.length) {
      // Update state with the new selected options and call handleChange
      setSelectedOptions(updatedSelectedOptions);
      handleChange(
        updatedSelectedOptions.map((option) => getOptionValue(option))
      );

      // Remove the delimiters first and then the emails from the input value
      value = value.replaceAll(";", "").replaceAll(",", "");
      updatedSelectedOptions.forEach((email) => {
        if (isString(email)) {
          value = value.replace(email, "").trim();
        }
      });
    }
    setInputValue(value);
    onInputChange?.(value);
  }

  function filterOptions(): NonStringOption[] {
    const lowerInputValue = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        !selectedValues.includes(getOptionValue(option).toLowerCase()) &&
        getOptionLabel(option).toLowerCase().includes(lowerInputValue)
    );
  }

  return {
    selectedOptions,
    inputValue,
    onAutocompleteChange,
    onAutocompleteBlur,
    onAutocompleteInputChange,
    filterOptions,
  };
}
