import { MemberTypes } from "@custom-types/member-types";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  UploadedData,
  UploadedDataTableHeaders,
} from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { getMemberNameById } from "@utils/member-utils";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { Typography } from "@mui/material";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { getDeviceType } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-utils";
import { FormatDate } from "@hooks/use-date-time";
import { DateTime } from "luxon";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** Function to format date */
  formatDate: FormatDate;
}

/**
 * Returns an object with the uploaded data table columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getUploadedDataTableColumns({
  companyMembers,
  projectMembers,
  formatDate,
}: Props): Record<UploadedDataTableHeaders, GridColDef> {
  return {
    [UploadedDataTableHeaders.name]: {
      field: UploadedDataTableHeaders.name,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: UploadedData }>) => {
        const name = params.row.entity.name;
        return (
          <FaroTableTextCell
            text={name}
            sx={{
              fontWeight: "bold",
            }}
          />
        );
      },
      valueGetter: (_, row: { entity: UploadedData }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [UploadedDataTableHeaders.clusterPath]: {
      field: UploadedDataTableHeaders.clusterPath,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: UploadedData }>) => {
        const clusterPath = params.row.entity.clusterPath;
        return <FaroTableTextCell text={clusterPath} />;
      },
      valueGetter: (_, row: { entity: UploadedData }) => row.entity.clusterPath,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [UploadedDataTableHeaders.createdBy]: {
      field: UploadedDataTableHeaders.createdBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: UploadedData }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.createdBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: UploadedData }) =>
        getMemberNameById({
          memberId: row.entity.createdBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [UploadedDataTableHeaders.createdAt]: {
      field: UploadedDataTableHeaders.createdAt,
      minWidth: 160,
      flex: 0.75,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: UploadedData }>) => {
        const date = formatDate(
          params.row.entity.createdAt,
          DateTime.DATETIME_MED
        );
        return <FaroTableTextCell text={date} />;
      },
      valueGetter: (_, row: { entity: UploadedData }) =>
        new Date(row.entity.createdAt),

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [UploadedDataTableHeaders.deviceType]: {
      field: UploadedDataTableHeaders.deviceType,
      minWidth: 100,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<{ entity: UploadedData }>) => {
        const deviceType = getDeviceType(params.row.entity.type);
        return <FaroTableTextCell text={deviceType} />;
      },
      valueGetter: (_, row: { entity: UploadedData }) =>
        getDeviceType(row.entity.type),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [UploadedDataTableHeaders.actions]: {
      field: UploadedDataTableHeaders.actions,
      type: "actions",
      align: "right",
      minWidth: 120,
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<{ entity: UploadedData }>) => {
        return params.row.entity.isProcessing ? (
          <>
            <Typography
              sx={{
                color: sphereColors.blue500,
                fontSize: "12px",
                fontWeight: "600",
                ...withEllipsis,
              }}
            >
              Processing
            </Typography>
            <FaroButtonSpinner loadingTrackColor={sphereColors.gray200} />
          </>
        ) : null;
      },
    },
  };
}
