import { getHBDashboardUrl } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-utils";
import { SidebarItemProps } from "@components/sidebar/sidebar-items";
import { SidebarHolobuilderTooltip } from "@components/sidebar/sidebar-holobuilder/sidebar-holobuilder-tooltip";
import { List, Stack, tooltipClasses } from "@mui/material";
import { useMemo, useState } from "react";
import { useAppSelector } from "@store/store-helper";
import { isBetaTestingEnabledSelector } from "@store/app/app-selector";
import { hasCompanySubscriptionRoles } from "@utils/access-control/company/company-access-control";
import { fetchingUserFlagsSelector } from "@store/user/user-selector";
import {
  fetchingSdbCompanyFlagsSelector,
  selectedCompanyContextSelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { SidebarEvents } from "@utils/track-event/track-event-list";
import { APITypes } from "@stellar/api-logic";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroSwitch } from "@components/common/faro-switch";
import { SendFeedbackDialog } from "@components/header/send-feedback-dialog";
import { sphereColors } from "@styles/common-colors";
import { FontWeights } from "@faro-lotv/flat-ui";

interface Props {
  /** Flag whether the sidebar should be shown full size or minified */
  isSidebarFullSize: boolean;

  /** Item to show in the sidebar. */
  sidebarItem: SidebarItemProps;
}

/** Renders a sidebar item that navigates to the HoloBuilder dashboard */
export function SidebarHolobuilderItem({
  isSidebarFullSize,
  sidebarItem,
}: Props): JSX.Element | null {
  const { isFetchingCurrentUser } = useAppSelector(fetchingUserFlagsSelector);
  const companyContext = useAppSelector(selectedCompanyContextSelector);
  const { isFetchingSelectedCompanyContext } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const { trackAsyncEvent } = useTrackEvent();
  const { hasUserPermissionCompanyLevel } = useHasUserValidRoleCompanyLevel();

  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(true);
  const [shouldShowFeedbackDialog, setShouldShowFeedbackDialog] =
    useState<boolean>(false);

  const shouldShowItem = useMemo(() => {
    // Don't show item while the required backend request are being fetched
    if (
      isFetchingCurrentUser ||
      isFetchingSelectedCompanyContext ||
      !companyContext
    ) {
      return false;
    }

    // Don't show the toggle for Sphere Legacy migrated workspaces
    if (!selectedSdbCompany || selectedSdbCompany.isSphereXGExclusive) {
      return false;
    }

    // Don't show a sidebar item if the item is in beta testing and beta testing is not enabled
    if (sidebarItem.shouldShowOnlyInBeta && !isBetaTestingEnabled) {
      return false;
    }

    // This is a special check for the HoloBuilder toggle. Don't show it if the user
    // has a Orbis/Connect account, since they should not be aware of HoloBuilder.
    if (
      hasCompanySubscriptionRoles({
        companyContext,
        requiredCompanySubscriptionRole: [
          APITypes.EUserSubscriptionRole.globalOrbisProcessing,
        ],
      })
    ) {
      return false;
    }

    // If the company is set to only have access to the Sphere Dashboard, don't show the toggle.
    if (
      hasCompanySubscriptionRoles({
        companyContext,
        requiredCompanySubscriptionRole: [
          APITypes.EUserSubscriptionRole.sphereDashboard,
        ],
      })
    ) {
      return false;
    }

    // Only show the items that the user has access to.
    return hasUserPermissionCompanyLevel({
      roleName: sidebarItem.requiredRoleCompanyLevel,
    });
  }, [
    companyContext,
    hasUserPermissionCompanyLevel,
    isBetaTestingEnabled,
    isFetchingCurrentUser,
    isFetchingSelectedCompanyContext,
    selectedSdbCompany,
    sidebarItem.requiredRoleCompanyLevel,
    sidebarItem.shouldShowOnlyInBeta,
  ]);

  async function navigateToHBDashboard(): Promise<void> {
    await trackAsyncEvent({
      name: SidebarEvents.navigateToHBDashboard,
    });

    const hbUrl = getHBDashboardUrl();
    window.location.assign(hbUrl);
  }

  async function handleSwitchChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const willBeChecked = event.target.checked;

    await trackAsyncEvent({
      name: SidebarEvents.toggleSDBAccess,
      props: {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Amplitude event property
        UseNewDashboard: willBeChecked,
      },
    });

    if (!willBeChecked) {
      setShouldShowFeedbackDialog(true);
    }

    setIsSwitchChecked(willBeChecked);
  }

  if (!shouldShowItem) {
    return null;
  }

  return (
    <List
      sx={{
        paddingY: 0,
        mb: "24px",
      }}
    >
      <SphereTooltip
        title={<SidebarHolobuilderTooltip />}
        tooltipProps={{
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: 320,
            },
          },
        }}
      >
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent="space-around"
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          {isSidebarFullSize && (
            <Stack>
              <div
                style={{
                  color: sphereColors.gray800,
                  fontSize: "10px",
                  lineHeight: "14px",
                }}
              >
                Try the
              </div>
              <div
                style={{
                  color: sphereColors.gray800,
                  fontSize: "12px",
                  fontWeight: FontWeights.Bold,
                  letterSpacing: "0.48px",
                  textTransform: "uppercase",
                }}
              >
                New Dashboard
              </div>
            </Stack>
          )}
          <FaroSwitch
            checked={isSwitchChecked}
            onChange={handleSwitchChange}
            sx={{ position: "relative", top: "6px" }}
          />
        </Stack>
      </SphereTooltip>

      <SendFeedbackDialog
        shouldShowFeedbackDialog={shouldShowFeedbackDialog}
        setShouldShowFeedbackDialog={setShouldShowFeedbackDialog}
        featureName="Switch to HB Dashboard"
        confirmText="Submit and switch back"
        cancelText="Switch without feedback"
        feedbackQuestionText="Before you switch back, can you share some feedback?"
        onSubmitFeedback={navigateToHBDashboard}
        onCancelFeedback={navigateToHBDashboard}
        onCloseFeedback={() => setIsSwitchChecked(true)}
      />
    </List>
  );
}
