import { IElementElsDataSet } from "@custom-types/i-elements-types";
import { RegistrationRevision } from "@faro-lotv/service-wires";

/** Headers for the published data table */
export enum PublishedDataTableHeaders {
  /** Name of the entity */
  name = "Name",

  /** User who created the entity */
  createdBy = "Created by",

  /** The time point date of the entity */
  timePoint = "Time point",

  /** Action buttons or additional info to show */
  actions = "Actions",
}

/** Published ELS dataset merged from registered ELS scans */
export interface PublishedElsData extends RegistrationRevision {
  /**
   * ELS data set IElement.
   * The ID, name and createdAt values of this IElement are used to get
   * the Viewer deep-link, data set name and time point respectively.
   */
  element?: IElementElsDataSet;
}

/** All possible published data types */
export type PublishedData = PublishedElsData;
