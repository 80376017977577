import { FaroButtonContained } from "@components/common/faro-button-contained";
import { BaseTeamProps } from "@custom-types/teams-types";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { useMemo, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { isValidEmail } from "@utils/member-utils";
import { APITypes } from "@stellar/api-logic";
import { useCompanyMembers } from "@hooks/use-company-members";
import { createMemberOption } from "@components/common/members-autocomplete/members-autocomplete-utils";
import { AutoCompleteMemberOption } from "@components/common/members-autocomplete/members-autocomplete-types";
import { useAppParams } from "@router/router-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { addMembersToTeam } from "@store/teams/teams-slice-thunk";
import { useToast } from "@hooks/use-toast";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { nounPluralize } from "@utils/data-display";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { selectedTeamMembersSelector } from "@store/teams/teams-selector";
import { FaroButton } from "@components/common/faro-button";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props extends BaseTeamProps {
  /**
   * Determines the variant of the invite member button
   *  - "outlined" (default): Contained button with white background and blue border
   *  - "filled": Normal button with blue background
   */
  buttonVariant?: "outlined" | "filled";
}

/** Maximum number of members to invited that supported by backend */
const MAX_INVITABLE_MEMBERS = 10;

/** Renders the invite member button and the functionality in team page */
export function InviteMemberToTeam({
  team,
  buttonVariant = "outlined",
}: Props): JSX.Element | null {
  const companyMembers = useCompanyMembers();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { companyId } = useAppParams();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();
  const teamMembers = useAppSelector(selectedTeamMembersSelector);
  const { canInviteMembersToTeam } = useHasUserValidRoleCompanyLevel();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [message, setMessage] = useState<AutoCompleteMessage | undefined>();

  /** Flag whether the input field in the autocomplete component has a valid email */
  const [isMemberInputValid, setIsMemberInputValid] = useState<boolean>(false);

  // Disable the `Send Invite` button if no user has been selected and the input is not a valid email.
  const shouldSendInviteDisable = useMemo(
    () =>
      (!selectedMembers.length && !isMemberInputValid) ||
      selectedMembers.length > MAX_INVITABLE_MEMBERS,
    [isMemberInputValid, selectedMembers.length]
  );

  /** Reformation company members data for faro autocomplete component */
  const memberOptions: AutoCompleteMemberOption[] = useMemo(
    () =>
      companyMembers.map((member) => {
        // This only checks the sample members in the team
        // Which doesn't include all the members of the team
        const isAlreadyMemberInTeam = team.sampleMembers.some(
          (teamMember) => member.identity === teamMember.userResponse.identity
        );

        return createMemberOption({
          member,
          isDisabled: isAlreadyMemberInTeam,
          disabledMessage: isAlreadyMemberInTeam
            ? `Already in this ${TEAM_DISPLAY_NAME}`
            : "",
        });
      }),
    [companyMembers, team.sampleMembers]
  );

  if (!canInviteMembersToTeam) {
    return null;
  }

  /** Default setup when invite dialog open */
  function onOpenInviteDialog(): void {
    setMessage(undefined);
    setSelectedMembers([]);
    setIsDialogOpen(true);
  }

  function handleMemberSelect(members: APITypes.UserIdentity[]): void {
    const isMembersContainsEmail = members.some((member) =>
      isValidEmail(member)
    );

    if (members.length > MAX_INVITABLE_MEMBERS) {
      // Showing info message if user already selected 10 or more members
      setMessage({
        type: "error",
        helperText:
          "Please reduce your selection to a maximum of 10 members per invite request.",
      });
      setIsMemberInputValid(false);
    } else if (isMembersContainsEmail) {
      // Show hints about adding or selecting an email
      setMessage({
        type: "info",
        helperText: `Invitation to this ${TEAM_DISPLAY_NAME} will be sent to the new email addresses`,
      });
    } else {
      setMessage(undefined);
    }
    setSelectedMembers(members);
  }

  /** Submit members to be invited */
  async function handleConfirm(): Promise<void> {
    trackEvent({
      name: TeamEvents.inviteMember,
      props: { numberOfMembers: selectedMembers.length },
    });

    try {
      if (!companyId) {
        throw new Error("companyId is required");
      }

      setIsDialogOpen(false);

      // Add members
      const response = await dispatch(
        addMembersToTeam({
          coreApiClient,
          companyId: companyId,
          teamId: team.id,
          members: selectedMembers,
        })
      ).unwrap();

      // Show response messages after adding members
      // Errors of creating team is handled by error slice
      const additionalMessages: string[] = [];
      if (response.errorData) {
        response.errorData.forEach((error) => {
          additionalMessages.push(error.message);
        });
      }

      // The status of the response
      // success: When all the invites go fine
      // warning: When some of the invites goes fine and some are failed
      // error: When all the invites are failed
      showToast({
        type: response.status,
        message: "Invitations sent",
        description: additionalMessages.map((message, index) => (
          <li key={index}>
            <var>{message}</var>
          </li>
        )),
      });
    } catch (error) {
      handleErrorWithToast({
        id: `inviteMemberToTeam-${Date.now().toString()}`,
        title: `Cannot invite the ${nounPluralize({
          shouldShowCounter: false,
          counter: selectedMembers.length,
          word: "member",
        })}`,
        error,
      });
    }
  }

  /** Return true if the new email is valid and it doesn't exist in the team */
  function isNewEmailValid(email: string): boolean {
    return isValidEmail(email) && !isAlreadyMemberInCurrentTeam(email);
  }

  /** Return true if member already exist with same email in the team */
  function isAlreadyMemberInCurrentTeam(email: string): boolean {
    return teamMembers.some(
      (member) =>
        member.userResponse.email === email ||
        member.userResponse.identity === email
    );
  }

  /**
   * Function to be called when the input value changes
   * It sets the valid emails to the addedEmails state and removes them from the input value
   */
  function onInputChange(value: string): void {
    const isValueExistOnMemberList = memberOptions.some(({ label }) =>
      label.includes(value)
    );

    if (!value) {
      // Removing message on clearing the search text
      setMessage(undefined);
      setIsMemberInputValid(false);
    } else if (!isValueExistOnMemberList && !isValidEmail(value)) {
      // Showing error message for new and invalid email
      setMessage({ type: "error", helperText: "Enter a valid email" });
      setIsMemberInputValid(false);
    } else if (isAlreadyMemberInCurrentTeam(value)) {
      // Showing info message if member already exist in the team
      setMessage({
        type: "info",
        helperText: `A member with the same email already exists in the ${TEAM_DISPLAY_NAME}`,
      });
      setIsMemberInputValid(false);
    } else {
      if (isValidEmail(value)) {
        setIsMemberInputValid(true);
      }
      setMessage(undefined);
    }
  }

  return (
    <>
      {buttonVariant === "filled" ? (
        <FaroButton onClick={onOpenInviteDialog}>Invite members</FaroButton>
      ) : (
        <FaroButtonContained onClick={onOpenInviteDialog}>
          Invite members
        </FaroButtonContained>
      )}

      <FaroDialog
        title={`Invite members to ${TEAM_DISPLAY_NAME}`}
        confirmText="Send Invite"
        open={isDialogOpen}
        onConfirm={handleConfirm}
        isConfirmDisabled={shouldSendInviteDisable}
        onClose={() => setIsDialogOpen(false)}
      >
        <Grid maxWidth="100%" width="70vw">
          <Stack>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={handleMemberSelect}
              onInputChange={onInputChange}
              validateNewOption={isNewEmailValid}
              message={message}
              labelTitle="Members"
              hasAutoFocus={true}
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
