import { CaptureTreeEntityType } from "@faro-lotv/service-wires";
import { DeviceType } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";

/**
 * @returns The device type for a capture tree entity type
 *
 * @param captureTreeEntityType Capture tree entity type
 */
export function getDeviceType(
  captureTreeEntityType: CaptureTreeEntityType
): DeviceType {
  switch (captureTreeEntityType) {
    case CaptureTreeEntityType.elsScan:
      return DeviceType.blink;
    case CaptureTreeEntityType.focusScan:
      return DeviceType.focus;
    case CaptureTreeEntityType.orbisScan:
      return DeviceType.orbis;
    default:
      return DeviceType.unknown;
  }
}
