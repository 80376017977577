import {
  MemberTableSubject,
  TeamMemberBulkButtonComponents,
} from "@components/common/faro-table/faro-table-types";
import { BaseMembersProps } from "@custom-types/member-types";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useEffect } from "react";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { TableType } from "@utils/track-event/track-event-list";
import { useAppDispatch } from "@store/store-helper";
import { getMembersColumns } from "@components/table/members/members-column/members-columns";
import { useMediaQueryList } from "@hooks/use-media-query";
import { MemberBulkButtonComponents } from "@components/common/faro-table/faro-table-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { SdbProject } from "@custom-types/project-types";
import { useDateTime } from "@hooks/use-date-time";

interface Props extends Partial<BaseCompanyIdProps>, BaseMembersProps {
  /** The selected project if there is one */
  project?: SdbProject | null;

  /** Recognizes on which context the table is being used */
  tableSubject: MemberTableSubject;

  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** The selected group if there is one */
  group?: SphereDashboardAPITypes.IGroupDetails;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: MemberHeaders[];

  /** List of all the bulk actions and callbacks */
  bulkActionButtons?:
    | MemberBulkButtonComponents
    | TeamMemberBulkButtonComponents;

  /** The selected snapshot if there is one */
  snapshot?: CoreAPITypes.IProjectSnapshot;

  /** The selected team if there is one */
  team?: SphereDashboardAPITypes.ITeam;

  /** Whether to hide the checkbox on each row or not */
  shouldHideCheckbox?: boolean;
}

/** Listing all the members of a subject such as a company or a project in a table */
export function MembersTable({
  companyId,
  members,
  project,
  isLoading,
  tableSubject,
  group,
  requiredColumns,
  bulkActionButtons,
  snapshot,
  team,
  shouldHideCheckbox,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();

  const { isMedium, isScreenLgAndLarger } = useMediaQueryList();

  const { formatDate } = useDateTime();

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    switch (tableSubject) {
      case "workspace":
        dispatch(setTableType(TableType.companyMembers));
        break;

      case "project":
        dispatch(setTableType(TableType.projectMembers));
        break;

      case "group":
        dispatch(setTableType(TableType.groupMembers));
        break;

      case "snapshot":
        dispatch(setTableType(TableType.snapshotMembers));
        break;

      case "team":
        dispatch(setTableType(TableType.teamMembers));
        break;
    }

    return () => {
      dispatch(resetTableState());
    };
  }, [tableSubject, dispatch]);

  return (
    <FaroTable
      entities={members}
      uniqueIdKey={"identity"}
      isLoading={isLoading}
      columns={requiredColumns.map(
        (requiredColumn) =>
          getMembersColumns({
            companyId: companyId ?? "",
            project,
            tableSubject,
            group,
            snapshot,
            team,
            formatDate,
            options: {
              isScreenLgAndLarger,
              isMedium,
            },
          })[requiredColumn]
      )}
      bulkActionButtons={bulkActionButtons}
      shouldHideCheckbox={shouldHideCheckbox}
    />
  );
}
