import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { TeamTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { BaseTeamProps } from "@custom-types/teams-types";
import { InviteMemberToTeam } from "@pages/members/teams/invite-member-to-team";
import { EditableTeamName } from "@pages/members/teams/team-details/editable-team-name";

interface Props extends Partial<BaseTeamProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Info bar for the team overview tabs
 */
export function TeamDetailsInfoBar({
  team,
  isLoading = false,
}: Props): JSX.Element {
  const { teamTabs } = useAppParams();
  const { canEditTeams } = useHasUserValidRoleCompanyLevel();

  if (!team || isLoading) {
    // Early exit with a loading skeleton if the team is not yet loaded
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          columnSize: 3,
          content: (
            <EditableTeamName
              team={team}
              isEditable={teamTabs === TeamTabs.members && canEditTeams}
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Members"
              initialValue={team.memberCount}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={
        teamTabs === TeamTabs.members ? (
          <InviteMemberToTeam team={team} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to keep the position of items constant
          <></>
        )
      }
    />
  );
}
