import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  setIsAlphaTestingEnabled,
  setIsBetaTestingEnabled,
} from "@store/app/app-slice";
import { useSearchParams } from "react-router-dom";
import { IReactChildrenOnly } from "@custom-types/types";
import { QueryParams } from "@router/route-params";
import { currentUserSelector } from "@store/user/user-selector";
import { isInternalUser } from "@utils/user-utils";

/**
 * Component that validates whether a query param exist or not all over the app
 * For example, it can be used to check whether "alpha" or "beta" features are enable or not
 */
export function QueryParamsLoader({
  children,
}: IReactChildrenOnly): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const currentUser = useAppSelector(currentUserSelector);
  const isUserInternal = useMemo(
    () => currentUser && isInternalUser(currentUser.email),
    [currentUser]
  );

  const enableBetaParam = searchParams.get(QueryParams.enableBeta);
  const enableAlphaParam = searchParams.get(QueryParams.enableAlpha);

  // Update store when the beta features exist in the query param
  useEffect(() => {
    if (!enableBetaParam) {
      return;
    }

    const lowerCaseStr = enableBetaParam.toLowerCase();
    dispatch(setIsBetaTestingEnabled(lowerCaseStr === "true"));
  }, [dispatch, enableBetaParam]);

  // Update store when the alpha features exist in the query param
  useEffect(() => {
    if (!enableAlphaParam || !isUserInternal) {
      return;
    }

    const lowerCaseStr = enableAlphaParam.toLowerCase();
    dispatch(setIsAlphaTestingEnabled(lowerCaseStr === "true"));
  }, [dispatch, enableAlphaParam, isUserInternal]);

  return children as JSX.Element;
}
