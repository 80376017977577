import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { getMemberNameById } from "@utils/member-utils";
import {
  PublishedData,
  PublishedDataTableHeaders,
} from "@pages/project-details/project-data-management/published-data/published-data-types";
import { FormatDate } from "@hooks/use-date-time";
import { DateTime } from "luxon";
import { PublishedDataActions } from "@pages/project-details/project-data-management/published-data/published-data-actions";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** Function to format date */
  formatDate: FormatDate;
}

/**
 * Returns a an object with the published data table columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getPublishedDataTableColumns({
  companyMembers,
  projectMembers,
  formatDate,
}: Props): Record<PublishedDataTableHeaders, GridColDef> {
  return {
    [PublishedDataTableHeaders.name]: {
      field: PublishedDataTableHeaders.name,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PublishedData }>) => {
        const dataSet = params.row.entity.element;
        const text = dataSet ? dataSet.name : "Not available yet";
        return (
          <FaroTableTextCell
            text={text}
            sx={{
              fontWeight: dataSet ? "bold" : "auto",
            }}
          />
        );
      },
      valueGetter: (_, row: { entity: PublishedData }) => row.entity.state,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PublishedDataTableHeaders.createdBy]: {
      field: PublishedDataTableHeaders.createdBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PublishedData }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.createdBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: PublishedData }) =>
        getMemberNameById({
          memberId: row.entity.createdBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PublishedDataTableHeaders.timePoint]: {
      field: PublishedDataTableHeaders.timePoint,
      minWidth: 180,
      flex: 1,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: PublishedData }>) => {
        const dataSet = params.row.entity.element;
        let text = "Not available yet";
        if (dataSet) {
          text = formatDate(dataSet.createdAt, DateTime.DATETIME_MED);
        }
        return <FaroTableTextCell text={text} />;
      },
      valueGetter: (_, row: { entity: PublishedData }) => {
        return new Date(row.entity.createdAt);
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [PublishedDataTableHeaders.actions]: {
      field: PublishedDataTableHeaders.actions,
      type: "actions",
      align: "right",
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PublishedData }>) => {
        return <PublishedDataActions publishedData={params.row.entity} />;
      },
    },
  };
}
