import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";

/**
 * Constructs a URL for publishing a registration revision for a specified project.
 *
 * @param {APITypes.ProjectId} projectId - The ID of the project for which the registration is being published.
 * @param {string} revisionId - The ID of the registration revision to be published.
 * @returns {string} A URL object pointing to the endpoint for publishing the specified registration revision.
 */
export function getInspectAndPublishToolUrl(
  projectId: APITypes.ProjectId,
  revisionId: string
): string {
  const url = new URL(
    `/data-preparation/project/${projectId}`,
    runtimeConfig.urls.sphereViewerUrl
  );

  url.searchParams.append("revisionId", revisionId);

  return url.href;
}
