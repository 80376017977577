import {
  EntityAdapter,
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { BaseEntityState, FetchingStatus } from "@store/store-types";
import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";
import {
  updateBackgroundTasks,
  fetchTasksByType,
} from "@store/sdb-background-tasks/sdb-background-tasks-thunk";

/**
 * State of the background tasks
 */
interface SdbBackgroundTasksState extends BaseEntityState<SdbBackgroundTask> {
  /** Pagination properties for this slice */
  pagination: {
    /** Current display page */
    page: number;

    /** Reference ID to the previous page. `null` if this is the first page */
    before: EntityId | null;

    /** Reference ID to the next page. `null` if this is the last page */
    after: EntityId | null;

    /** Total count of the background tasks. `null` if not known yet */
    totalCount: number | null;
  };

  /** Fetching properties for this slice */
  fetching: {
    /** Indicates if background tasks are being fetched from the backend */
    backgroundTasksFetchingStatus: FetchingStatus;
  };
}

/** Creates an entity adapter to store a map with all the background tasks that the user has access to */
export const sdbBackgroundTasksAdapter: EntityAdapter<SdbBackgroundTask> =
  createEntityAdapter({
    selectId: (sdbBackgroundTask) => sdbBackgroundTask.id,
  });

/** Initial SdbBackgroundTasksState state */
export const initialState: SdbBackgroundTasksState = {
  ...sdbBackgroundTasksAdapter.getInitialState(),
  pagination: {
    page: 0,
    before: null,
    after: null,
    totalCount: null,
  },
  fetching: {
    backgroundTasksFetchingStatus: FetchingStatus.uninitialized,
  },
};

/**
 * Slice to access the state of the the loaded background tasks
 */
const backgroundTasksSlice = createSlice({
  name: "sdbBackgroundTasks",
  initialState,
  reducers: {
    /**
     * Accepts a single sdbBackgroundTask entity and adds or updates it in the store.
     */
    upsertOne: sdbBackgroundTasksAdapter.upsertOne,

    /**
     * Accepts an array of sdbBackgroundTask entities and adds or updates them in the store.
     */
    upsertMany: sdbBackgroundTasksAdapter.upsertMany,

    /**
     * Accepts a single sdbBackgroundTask entity and removes it from the store.
     */
    removeOne: sdbBackgroundTasksAdapter.removeOne,

    /**
     * Accepts an array of sdbBackgroundTask entities and removes them from the store.
     */
    removeMany: sdbBackgroundTasksAdapter.removeMany,

    /**
     * Removes all the sdbBackgroundTask entities from the store.
     */
    removeAll: sdbBackgroundTasksAdapter.removeAll,

    /**
     * Resets the sdbBackgroundTask store state to the initial value.
     */
    resetSdbBackgroundTasksState: () => initialState,

    /**
     * Sets the page token for the page before the first fetched one
     */
    setBeforePage: (state, action: PayloadAction<EntityId | null>) => {
      state.pagination.before = action.payload;
    },

    /** Sets the page token for the page after the last fetched one */
    setAfterPage: (state, action: PayloadAction<EntityId | null>) => {
      state.pagination.after = action.payload;
    },

    /**
     * Sets the current page of the background tasks
     */
    setTotalBackgroundTasksCount: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.pagination.totalCount = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateBackgroundTasks.pending, (state) => {
        state.fetching.backgroundTasksFetchingStatus = FetchingStatus.pending;
      })
      .addCase(updateBackgroundTasks.fulfilled, (state, action) => {
        state.fetching.backgroundTasksFetchingStatus = FetchingStatus.succeeded;
        sdbBackgroundTasksAdapter.upsertMany(
          state,
          action.payload.sdbBackgroundTasks
        );
        state.pagination.totalCount = action.payload.totalCount;
        if (
          !state.pagination.before ||
          action.payload.shouldOverrideBeforePage
        ) {
          state.pagination.before = action.payload.before;
        }
        state.pagination.after = action.payload.after;
      })
      .addCase(updateBackgroundTasks.rejected, (state) => {
        state.fetching.backgroundTasksFetchingStatus = FetchingStatus.rejected;
      })

      .addCase(fetchTasksByType.pending, (state) => {
        state.fetching.backgroundTasksFetchingStatus = FetchingStatus.pending;
      })
      .addCase(fetchTasksByType.fulfilled, (state, action) => {
        state.fetching.backgroundTasksFetchingStatus = FetchingStatus.succeeded;
        sdbBackgroundTasksAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchTasksByType.rejected, (state) => {
        state.fetching.backgroundTasksFetchingStatus = FetchingStatus.rejected;
      });
  },
});

export const {
  upsertOne,
  upsertMany,
  removeOne,
  removeMany,
  removeAll,
  resetSdbBackgroundTasksState,
  setBeforePage,
  setAfterPage,
  setTotalBackgroundTasksCount,
} = backgroundTasksSlice.actions;

export const backgroundTasksReducer = backgroundTasksSlice.reducer;
