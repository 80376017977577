/** All possible tracking events for the user interaction with the Sidebar */
export enum SidebarEvents {
  expand = "expand sidebar",
  collapse = "collapse sidebar",
  openTab = "open sidebar tab",

  /** Triggers the event when user clicks on the Sphere logo to "go home" */
  goHome = "go home",

  /** Triggers the event when user navigates to HB Dashboard (after the feedback modal appeared) */
  navigateToHBDashboard = "navigate to holobuilder dashboard",

  /** Triggers when user clicks on the switch to navigate to the HB Dashboard */
  toggleSDBAccess = "toggle SXG dashboard access",
}

/** All possible tracking events for the user interaction with the Side Panel */
export enum SidePanelEvents {
  /** Trigger the event when user clicks in one row in the data table */
  openSidePanelData = "open side panel data",

  /** Trigger the event when the user opens a markup side panel */
  openSidePanelMarkups = "open side panel markups",
}

/** All possible tracking events for the feedback modal */
export enum FeedbackModalEvents {
  /** Triggers the event when user opens the feedback dialog */
  openFeedbackDialog = "open feedback dialog",

  /** Triggers the event when user clicks on the contact us/support button */
  contactSupport = "contact support",

  /**
   * Triggers the event when user clicks on the submit feedback button.
   * It should be used for any type of feedback and the feedback type should
   * be sent as a topic property
   */
  submitFeedback = "send product feedback",

  /** Triggers the event when user opens the feedback annotations dialog */
  openFeedbackDialogAnnotation = "open feedback annotations dialog",
}

/** Events to track in add ons page */
export enum AddOnsEvents {
  /** Triggers the event when user clicks on the submit request add-ons button */
  submitRequestAddOns = "submit request add-ons",

  /** Shows information about a particular add-on */
  openAddOnInformation = "open add-on information",
}

/** Possible tab properties for the "open sidebar tab" event */
export enum SidebarTabs {
  members = "members",
  projects = "projects",
  feedback = "feedback",
  groups = "groups",
  workspace = "workspace",
}

/** Events to track in which file type the user wants to export the data */
export enum ExportType {
  /** Exporting as a CSV file */
  exportAsCsv = "export as CSV",
}

/** Events to track which table the user wants to export its data as CSV */
export enum TableType {
  /** The list of groups table */
  groups = "groups",

  /** The list of projects in a group table */
  groupProjects = "group projects",

  /** The list of members in a group table */
  groupMembers = "group members",

  /** The list of members in a company table */
  companyMembers = "company members",

  /** The list of members in a project table */
  projectMembers = "project members",

  /** The list of members in a snapshot table */
  snapshotMembers = "snapshot members",

  /** The list of active projects table */
  activeProjects = "active projects",

  /** The list of archive projects table */
  archiveProjects = "archive projects",

  /** The list of background tasks table */
  backgroundTasks = "background tasks",

  /** The list of project markup tasks table */
  projectMarkups = "project markups",

  /** The list of projects of a group that a member belongs to */
  memberGroupProjects = "member group project",

  /** The project point clouds table */
  pointClouds = "point clouds",

  /** The list of teams table */
  teams = "teams",

  /** The list of members in a team table */
  teamMembers = "team members",
}

/** Events to track when user clicks on a particular button */
export enum ButtonEvents {
  /** Triggers the event when user clicks on the "load more" button */
  loadMore = "load more",

  /** Triggers the event when user clicks on the "manage cookies" button in the profile page */
  manageCookies = "manage cookies",
}

/** Events to track when user perform a bulk action like changing role of multiple members or removing members etc. */
export enum BulkActionEvents {
  /** Triggers when user clicks on the "change role" button for bulk action */
  bulkChangeRole = "bulk change role",

  /** Triggers when user clicks on the "remove member" button for bulk action */
  bulkRemoveMember = "bulk remove member",

  /** Triggers when user clicks on the "resend invitation" button for bulk action */
  bulkResendInvitation = "bulk resend invitation",

  /** Triggers when user clicks on the "send email" button for bulk action */
  bulkSendEmail = "bulk send email",

  /** Triggers when user clicks on the "change group project" button for bulk action */
  bulkChangeGroup = "bulk change group",

  /** Triggers when user clicks on the "delete project" button for bulk action */
  bulkDeleteProject = "bulk delete project",

  /** Triggers when user clicks on the "archive/unarchive project" button for bulk action */
  bulkChangeProjectStatus = "bulk change project status",

  /** Triggers when user clicks on the "Pairwise registration" button for bulk actions in the point clouds table */
  bulkPairwiseRegistration = "bulk pairwise registration",

  /** Triggers when user clicks on the "Delete Team" button for bulk actions */
  bulkDeleteTeam = "bulk delete team",
}

/** Events to track when user does changes directly inside the table */
export enum ProjectMemberEvents {
  /** Triggers when user clicks on the "change role" button */
  changeRole = "change role",

  /** Triggers when user clicks on the "remove member" button */
  removeMember = "remove member from project",

  /** Triggers when user clicks on the resend invitation button */
  resendInvitation = "resend invitation",
}

/** Events to track when user does changes directly inside the group members table */
export enum GroupMemberEvents {
  /** Triggers when user change the role of a group member */
  changeRole = "change group member role",

  /** Triggers when user clicks on the "remove member" button */
  removeMember = "remove member from group",
}

export enum WorkspaceMemberEvents {
  /** Triggers when user confirms to remove a member */
  removeMember = "remove member from workspace",
}

/** Events to track when user does changes directly inside the team members table */
export enum TeamMemberEvents {
  /** Triggers when user confirms to remove a member from team */
  removeMember = "remove member from team",
}

/** Events to track on the user profile page */
export enum UserProfile {
  /** Triggers the event when user modifies permission of other user */
  updatePermissions = "update permissions",

  /** Triggers the event when user modifies profile info like name or email */
  updateProfile = "update profile",

  /** Triggers the event when user expands a group to see the projects contains in the profile page */
  expandGroupProjects = "expand group projects",
}

export enum SearchbarEvents {
  /** Trigger the event when search a workspace member */
  searchForWorkspaceMember = "search for workspace members",

  /** Trigger the event when search a project */
  searchForProject = "search for project",

  /** Trigger the event when search a team */
  searchForTeam = "search for team",
}

/** All possible tracking events for the user interaction with the user menu */
export enum UserMenuEvents {
  /** Triggers the event when user opens the user menu */
  openUserMenu = "open user menu",

  /** Triggers the event when user clicks on the "profile" button */
  openProfilePage = "open profile page",

  /** Triggers the event when user clicks on the "about" button */
  openAboutDetails = "open about details",

  /** Triggers the event when user clicks on the "terms" button */
  openTermsDetails = "open terms details",

  /** Triggers the event when user clicks on the "privacy" button */
  openPrivacyDetails = "open privacy details",

  /** Triggers the event when user clicks on the "imprint" button */
  openImprintDetails = "open imprint details",

  /** Triggers the event when user clicks on the "log out" button */
  logOut = "log out",

  /** Triggers the event when user opens the details page from a group */
  openGroupDetails = "open group details",
}

/** All possible tracking events for the user interaction with the language selector */
export enum LanguageSelectorEvents {
  /** Triggers the event when user changes the language */
  changeLanguage = "change language",
}

/** All possible tracking events for the user interaction with the breadcrumb */
export enum BreadcrumbEvents {
  navigateWithBreadcrumb = "navigate with breadcrumb",
}

/** All possible tracking events for the user interaction with projects */
export enum ProjectEvents {
  /** Triggers when project feature setting is updated */
  adjustFeatureSettings = "adjust feature settings",

  /** Triggers when project settings are updated */
  updateProjectSettings = "update project settings",

  /** Triggers when user confirm the delete project dialog to delete a project*/
  deleteProject = "delete project",

  /** Triggers the event when user clicks on the "active projects" tab */
  openActiveProjects = "open active projects",

  /** Triggers the event when user clicks on the "archived projects" tab */
  openArchivedProjects = "open archived projects",

  /** Triggers the event when user clicks on the "create new project" button */
  startCreateNewProject = "start create new project",

  /** Triggers the event when user finishes the creation of a new project */
  createNewProject = "create new project",

  /** Triggers the event when user clicks on the "open project" button */
  openProject = "open project",

  /** Triggers the event when user open the project details (project overview page) */
  openProjectDetails = "open project details",

  /** Triggers the event when user clicks on the "share project" button */
  shareProject = "share project",

  /** Triggers the event when user clicks on a project card to open the project overview */
  openProjectOverview = "open project overview",

  /** Triggers the event when user clicks on the "archive project" button */
  archiveProject = "archive project",

  /** Triggers the event when user clicks on the "unarchive project" button */
  unarchiveProject = "unarchive project",

  /** Triggers the event when user clicks on the "Send Invite" button */
  inviteMember = "invite member to project",

  /** Triggers the event when user clicks on the "Manage data" button */
  openDataManagement = "open data management",

  /** The user clicks on copy link when sharing a project */
  copyShareProjectLink = "copy share project link",
}

/** All possible events for the data management  */
export enum DataManagementEvents {
  /** Trigger the event when the user click on the "Inspect and publish" button of the prepared data section */
  openDataManagementInspectPublish = "Open data management inspect and publish",

  /** Trigger the event when the user click on the "Inspect" button of the published data section */
  openDataManagementInspect = "Open data management inspect",

  /** Triggers when the user clicks on the "Prepare data" button to trigger a capture tree registration */
  triggerCaptureTreeRegistration = "trigger capture tree registration",

  /** Triggers the event when user clicks on the "share" button */
  sharePublishedData = "share published data item",
}

/** All possible events when using tabs, defines all the pages that can be opened  */
export enum OpenTabEvents {
  /** Opens any tab inside the project details page */
  openProjectTab = "open project tab",

  /** Opens any tab inside the add-ons page */
  openAddOnsTab = "open add-ons tab",

  /** Opens any tab inside the group details page */
  openGroupTab = "open group tab",

  /** Opens any tab inside the projects page */
  openProjectsTab = "open projects tab",

  /** Opens any tab inside the analytics page */
  openAnalyticsTab = "open analytics tab",

  /** Opens any tab inside the page for member profile page */
  openMemberAccountTab = "open member account tab",

  /** Opens any tab inside the page for workspace settings */
  openWorkspaceSettingsTab = "open workspace settings tab",

  /** Opens any tab inside the page for workspace users */
  openWorkspaceUsersTab = "open workspace users tab",

  /** Opens any tab inside the page for a team */
  openTeamTab = "open team tab",
}

/** All possible tracking events for when the user changes the project details */
export enum ChangeProjectDetailsEvents {
  /** Triggers the event when user tries to change the project description */
  changeProjectDescription = "change project description",

  /** Triggers the event when user tries to change the project address */
  changeProjectAddress = "change project address",

  /** Triggers the event when user tries to change the project client */
  changeProjectClient = "change project client",

  /** Triggers the event when user tries to change the project name */
  changeProjectName = "change project name",

  /** Triggers the event when user tries to change the project access level */
  changeProjectAccessLevel = "change project access level",

  /** Trigger the event when user tries to change the project thumbnail */
  changeProjectThumbnail = "change project thumbnail",
}

/** All possible tracking events for when the user changes the group details */
export enum ChangeGroupDetailsEvents {
  /** Triggers the event when user tries to change the group name, sqftAssigned, imageUrl */
  changeGroupName = "change group name",

  /** Trigger the event when user tries to change the group thumbnail */
  changeGroupThumbnail = "change group thumbnail",
}

/** All possible tracking events for the user interaction with the workspace */
export enum WorkspaceEvents {
  /** Triggers the event when user selects a workspace */
  selectWorkspace = "select workspace",

  /** Triggers the event when user clicks on the contact support button */
  contactSupport = "contact support",

  /** Triggers the event when user update the workspace settings */
  updateSettings = "update settings",

  /** Triggers whenever the page is loaded for the first time */
  pageLoaded = "page loaded",

  /** Triggers when the user clicks on the "Request a Quote" button from the "Trial is about to expire" banner */
  requestAQuote = "request a quote",
}

/** All possible tracking events for user interaction with viewing data in different ways */
export enum DataViewEvents {
  /** Triggers the event when user selects the "card style" view */
  selectCardStyle = "select card style",

  /** Triggers the event when user selects the "list style" view */
  selectListStyle = "select list style",
}

/** Events to track when user does changes directly inside the snapshot members table */
export enum SnapshotEvents {
  /** Triggers when user clicks on the "remove member" button */
  removeMember = "remove member from snapshot",

  /** Triggers when user clicks on the "invite members" button */
  inviteMembers = "invite members to snapshot",

  /** Triggers when user clicks on the "send email" button */
  sendSnapshotEmail = "send snapshot email",

  /** Triggers when user clicks on the "copy snapshot link" button */
  copySnapshotLink = "copy snapshot link",

  /** Triggers when user clicks on the "open snapshot" button */
  openSnapshot = "open snapshot",

  /** Triggers when user clicks on the "delete snapshot" button */
  deleteSnapshot = "delete snapshot",

  /** Triggers when user expands the snapshot to view its members */
  viewSnapshotMembers = "view snapshot members",
}

export enum ErrorEvents {
  /** Triggered when a user opens a page it should not have access (e.g. by opening a deep link) */
  openForbiddenPage = "open forbidden page",

  /** Triggered when a user opens a forbidden page and then logs in with a different user */
  openForbiddenPageDifferentUser = "open forbidden page different user",

  /** Triggered when a user opens a page that does not exist */
  openNotFoundPage = "open not found page",
}

/** All possible events for user interaction with the project analytics tab */
export enum ProjectActivityEvents {
  openVideoModeTrajectory = "open videomode trajectory",
}

/** All possible events for user interaction with the project annotations tab */
export enum AnnotationEvents {
  /** Triggers the event when user clicks on the "share annotation" button */
  shareAnnotation = "share annotation",

  /** Triggers the event when user adds a new attachment */
  addNewAttachment = "add new attachment",

  /** Triggers the event when the user update any field */
  editAnnotation = "edit annotation",

  /** Triggers the event when user clicks on an attachment item to preview it */
  previewAttachment = "preview attachment",

  /** Triggers the event when user clicks on the download button */
  downloadAttachment = "download attachment",

  /** Triggers the event when user clicks on the delete button */
  deleteAttachment = "delete attachment",
}

/** All possible events for user interaction with the point clouds section of the project data tab */
export enum PointCloudEvents {
  /** Triggers the event when user clicks on the "share annotation" button */
  sharePointCloud = "share point cloud",
}

/** All possible events for tutorial page interactions */
export enum TutorialEvents {
  /** Triggers when user clicks on next button to view the next page */
  selectNextPage = "next tutorial page",

  /** Triggers when user clicks on back button to view the previous page */
  selectPreviousPage = "previous tutorial page",

  /** Triggers when user clicks on page indicator icons to jump to a certain page */
  jumpToPage = "jump to tutorial page",

  /** Triggers when user clicks on X button to skip (close) all the tutorial pages */
  skipTutorial = "skip tutorial",

  /** Triggers when user clicks on DONE button after viewing all the tutorial pages */
  finishTutorial = "finish tutorial",
}

/** All possible events for progress overview menu */
export enum ProgressOverviewEvents {
  /** Triggers when user clicks on progress overview button to view progress */
  openProgressOverview = "open progress overview",
}

/** All possible tracking events for the user interaction with user groups(teams) */
export enum TeamEvents {
  /** Triggers the event when user clicks on the "create new team" button */
  startCreateNewTeam = "start create new team",

  /** Triggers the event when user finishes the creation of a new team */
  createNewTeam = "create new team",

  /** Triggers the event when user open the team details */
  openTeam = "open team",

  /** Triggers the event when user tries to change the team name */
  changeTeamName = "change team name",

  /** Triggers the event when user tries to delete the team */
  deleteTeam = "delete team",

  /** Triggers the event when user clicks on the "Send Invite" button */
  inviteMember = "invite member to team",
}

/** All possible tracking events for the user interaction with the top app bar */
export enum AppBarEvents {
  /** User opens the help dropdown menu */
  openHelpMenu = "open help menu",

  /** User closes the help dropdown menu */
  closeHelpMenu = "close help menu",

  /** User opens any help menu item, e.g. help videos */
  openHelpMenuItem = "open help menu item",
}
