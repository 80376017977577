import { GroupTypes } from "@custom-types/group-types";
import { BaseMembersProps, MemberTypes } from "@custom-types/member-types";
import { CSVExporter } from "@stellar/web-core";
import {
  getMemberStatus,
  getPrettyName,
  isMemberActive,
} from "@utils/user-utils";
import { formatUserRoleType } from "@utils/data-display";
import { DASH } from "@utils/ui-utils";
import { getRoleHeader } from "@components/table/members/members-table-utils";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { BaseProjectsProps, SdbProject } from "@custom-types/project-types";
import {
  getProjectArea,
  getProjectClientName,
  getProjectManager,
  getSphereViewerUrl,
} from "src/utils/project-utils";
import { Markup } from "@custom-types/project-markups-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getMarkupAssigneeName } from "@pages/project-details/project-markups/markup-assignee-utils";
import { getMarkupLocationValue } from "@pages/project-details/project-markups/markup-location-utils";
import { getIntegrationName } from "@pages/project-details/project-markups/markup-integration-utils";
import { getMarkupStatusLabel } from "@pages/project-details/project-markups/status/markup-status-utils";
import { getMemberNameById } from "@utils/member-utils";
import { FormatDate } from "@hooks/use-date-time";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";

/**
 * Create CSV file out of groups array and create a download handler
 */
export function downloadGroupDataAsCSV(
  groups: GroupTypes[],
  formatDate: FormatDate
): void {
  const csvData = groups.map((group) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    ID: sanitizeForCSV(group.id),
    Name: sanitizeForCSV(group.name),
    "Created At": formatDate(group.createdAt),
    Managers: group.numManagers,
    Projects: group.numProjects,
    "Sqft Assigned": sanitizeForCSV(
      group.sqftAssigned !== -1 ? group.sqftAssigned : "unrestricted"
     ),
    "Sqft Used": group.sqftUsed,
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, "Groups List.csv");
}

/**
 * Create CSV file out of teams array and create a download handler
 */
export function downloadTeamDataAsCSV(
  teams: SphereDashboardAPITypes.ITeam[],
  formatDate: FormatDate
): void {
  const csvData = teams.map((team) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    ID: sanitizeForCSV(team.id),
    Name: sanitizeForCSV(team.name),
    Description: sanitizeForCSV(team.description ?? ""),
    "Number of Members": team.memberCount,
    "Created At": formatDate(team.createdAt),
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(
    csvData,
    `${capitalizeFirstLetter(TEAMS_DISPLAY_NAME)} List.csv`
  );
}

interface DownloadMembersDataAsCSVProps extends BaseMembersProps {
  /** The name of the file to be downloaded */
  fileName: string;

  /** The subject type of the member */
  subjectType: MemberTableSubject;
}

/**
 * Create CSV file out of project members array and create a download handler
 */
export function downloadMembersDataAsCSV({
  fileName,
  members,
  subjectType,
}: DownloadMembersDataAsCSVProps): void {
  const csvData = members.map((member) => {
    const userData = {
      /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
      ID: sanitizeForCSV(member.identity),
      Name: sanitizeForCSV(
        isMemberActive(member) ? getPrettyName(member) : DASH
      ),
      Email: sanitizeForCSV(member.email),
      status: sanitizeForCSV(getMemberStatus({ member })),
      /* eslint-enable */
    };

    // Skipping Role column when subject type is team
    if (subjectType !== "team") {
      Object.assign(userData, {
        [getRoleHeader(subjectType)]: sanitizeForCSV(formatUserRoleType(member.role)),
      });
    }

    return userData;
  });

  CSVExporter.toFileDownload(csvData, fileName);
}

interface DownloadProjectsDataAsCSVProps extends BaseProjectsProps {
  /** The name of the file to be downloaded */
  fileName: string;

  /** The function to format date. Needs to be passed manually when not used in a hook */
  formatDate: FormatDate;
}

export function downloadProjectsDataAsCSV({
  fileName,
  projects,
  formatDate,
}: DownloadProjectsDataAsCSVProps): void {
  const csvData = projects.map((project) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    "Project Name": sanitizeForCSV(project.name),
    Client: sanitizeForCSV(getProjectClientName(project)),
    Area: sanitizeForCSV(getProjectArea(project)),
    "Project Manager Name": sanitizeForCSV(projectManagerName(project)),
    "Project Manager Email": sanitizeForCSV(
      getProjectManager(project)?.email || DASH
    ),
    Edited: formatDate(project.modifiedAt),
    "Created At": formatDate(project.createdAt),
    "Project Link": sanitizeForCSV(
      getSphereViewerUrl({ projectId: project.id }).href
    ),
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, fileName);
}

/**
 * Returns the project manager name
 * If the project manager is active, it will return the name otherwise it will return DASH
 */
function projectManagerName(project: SdbProject): string {
  const projectManager = getProjectManager(project);

  if (projectManager && isMemberActive(projectManager)) {
    return getPrettyName(projectManager);
  }
  return DASH;
}

interface DownloadMarkupsDataAsCSVProps {
  /** List of selected markups in table */
  markups: Markup[];

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** The name of the file to be downloaded */
  fileName: string;

  /** Callback to return the URL of the markup either in WebEditor or Viewer */
  markupHref: (markup: Markup) => string | undefined;

  /** The function to format date. Needs to be passed manually when not used in a hook */
  formatDate: FormatDate;
}

/** Create CSV file out of markups array and create a download handler */
export function downloadMarkupDataAsCSV({
  markups,
  companyMembers,
  projectMembers,
  fileName,
  markupHref,
  formatDate,
}: DownloadMarkupsDataAsCSVProps): void {
  const csvData = markups.map((markup) => ({
    /* eslint-disable @typescript-eslint/naming-convention -- Many of the keys are used for CSV export */
    ID: sanitizeForCSV(markup.id),
    Name: sanitizeForCSV(markup.name),
    Assignee:
    sanitizeForCSV(
      getMarkupAssigneeName({
        assignee: markup.assignee,
        companyMembers,
        projectMembers,
      }) ?? DASH
    ),
    "Created At": formatDate(markup.createdAt),
    "Created By":
      sanitizeForCSV(  
        getMemberNameById({
            memberId: markup.createdBy,
            companyMembers,
            projectMembers,
          }) ?? DASH
      ),
    "Due Date": markup.dueDate?.value ? formatDate(markup.dueDate.value) : DASH,
    Status: sanitizeForCSV(
      getMarkupStatusLabel(markup.status?.value)
    ),
    Location: sanitizeForCSV(
      getMarkupLocationValue({ markup })
    ),
    Tags: sanitizeForCSV(
       markup.labels?.map((label) => label.name).join(", ") ?? DASH 
      ),
    "Sync With": sanitizeForCSV(
      getIntegrationName(markup.externalMarkup) ?? DASH
    ),
    "Number of Attachments": markup.attachments.length,
    Description: sanitizeForCSV(markup.descr ?? DASH),
    Url: sanitizeForCSV(markupHref(markup) ?? DASH),
    /* eslint-enable */
  }));

  CSVExporter.toFileDownload(csvData, fileName);
}

 /** 
  * Removes leading dangerous characters ('=', '+', '-', '@')
  * and white spaces from a string for secure CSV export.
  */
export function sanitizeForCSV(value: string| number): string | number {
  if (typeof value === "string") {
    return value.replace(/^([\s=+\-@])+/, "");;
  }
  return value;
}
