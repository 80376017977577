import { AsyncUtils } from "@stellar/web-core";

const SecondsInMilliseconds = 1000;

/** The default classes of html element from being translated */
export enum BlockedClasses {
  "noLocalize" = "no-localize",
}

/**
 * Minimal wrapper around localize-js library
 * Docs: https://help.localizejs.com/docs/library-api
 */
export const Localization = {
  /**
   * Promise that indicates if Localization module has been initialized indicated by its initialize event
   * resolved: Localization module is initialized correctly
   * rejected: Localization module is initialized incorrectly (for example with an empty key)
   * pending: init function is still executing
   * undefined: init function was not called yet
   */
  isInitialized: undefined as Promise<void> | undefined,

  selectedLanguage(): string {
    return (Localize && Localize.getLanguage()) || "";
  },

  /** Initialize Localize */
  async init(
    key: string,
    options?: Omit<Partial<LocalizeJS.Context.Options>, "key">
  ): Promise<void> {
    if (!key) {
      throw Error("The key cannot be empty. Please provide a valid one");
    }

    // This is needed to make sure Localize is available (in html head) at the time of calling it
    while (!Localize) {
      await AsyncUtils.timeoutPromise(SecondsInMilliseconds);
    }

    Localization.isInitialized = new Promise<void>((resolve, reject) =>
      Localize.on("initialize", (localizeData) => {
        // If localize has been initialized, this event will give an object
        // containing the key that was used for initialization to this callback.
        // If the key is empty the connection was not established successfully.
        if (localizeData && localizeData.key && localizeData.key !== "") {
          resolve();
        } else {
          reject();
        }
      })
    );

    let additionalBlockedClasses: string[] = [];
    if (options && options.blockedClasses) {
      additionalBlockedClasses = options.blockedClasses;
    }

    let allBlockedIds: string[] = [];
    if (options && options.blockedIds) {
      allBlockedIds = options.blockedIds;
    }

    const userLang = navigator.language ?? "";

    // TODO: Remove this, when we officially release the French translation
    // https://faro01.atlassian.net/browse/ST-2530
    // If the user's language is French, we don't want to autodetect the language
    // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
    const autodetectLanguage = !userLang.includes("fr");

    Localize.initialize({
      blockedClasses: [
        ...Object.values(BlockedClasses),
        ...additionalBlockedClasses,
      ],
      blockedIds: allBlockedIds,
      key,
      /* eslint-disable @typescript-eslint/naming-convention -- external package */
      rememberLanguage: true,
      retranslateOnNewPhrases: true,
      translateMetaTags: false,
      translateTitle: false,
      autodetectLanguage,
      /* eslint-enable */
    });
  },

  /**
   * A function that translates a string or HTMLElement to the current selectedLanguage. Wraps
   * translate from Localizejs and resolves it directly instead of using a callback
   *
   * @param input A string or HTMLElement that should be translated
   * @param variables An object of variables that will be replaced in the input, if it's a string
   * @return  A Promise. If selectedLanguage is different then the language of the input string/HTMLElement
   *          and a translation of it exists in localize, then a string or HTMLElement (depending on the
   *          input) will be returned translated. If not the input will just be translated as is.
   */
  async translate<T extends string | HTMLElement>(
    input: T,
    variables?: Record<string, string | number>
  ): Promise<T extends string ? string : HTMLElement> {
    await checkLocalizationInit();

    return new Promise((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Localize.translate(input, variables, resolve as any);
    });
  },

  /**
   * Formats a number according to the currently selected locale
   *
   * @param number:  The number that should be formatted
   * @param options: NumberFormatOptions that specify how the number should be formatted, for example if it should
   *                 be shortened or not
   * @returns The formatted string version of number respecting the given options
   */
  localizeNumber(number: number, options?: Intl.NumberFormatOptions): string {
    return new Intl.NumberFormat(
      Localization.selectedLanguage(),
      options
    ).format(number);
  },

  /**
   * Disables localization for a given HTML node
   *
   * @param node The HTML node that should not be localized
   */
  disableLocalization(node: HTMLElement): void {
    node.classList.add(BlockedClasses.noLocalize);
  },

  /**
   * Set the language the app should be translated to.
   *
   * @param languageCode: The selected language code as defined in localization library, e.g. en for English
   */
  async setLanguage(languageCode: string): Promise<void> {
    Localize.setLanguage(languageCode);
  },

  /**
   * Get all available languages as defined in the localization library. Returns a
   * promise containing an array with the LanguageObjects if successful or else the
   * error that was thrown
   */
  async fetchAvailableLanguages(): Promise<
    LocalizeJS.Context.LanguageObject[]
  > {
    await checkLocalizationInit();

    return new Promise<LocalizeJS.Context.LanguageObject[]>(
      (resolve, reject) => {
        Localize.getAvailableLanguages((err, languages) => {
          if (err) {
            reject(err);
            return;
          }

          resolve(languages);
        });
      }
    );
  },

  /**
   * Register a callback-function that will be executed every time the language changes
   * The callback function gets short code of the language that was set before and the
   * language that was changed to
   */
  registerSetLanguageCallback(
    callback: (data: LocalizeJS.Context.ChangeLanguageCode) => void
  ): void {
    Localize.on("setLanguage", callback);
  },

  /**
   * Unregister the callback-function from registerSetLanguageCallback
   */
  unregisterSetLanguageCallback(
    callback: (data: LocalizeJS.Context.ChangeLanguageCode) => void
  ): void {
    Localize.off("setLanguage", callback);
  },
};

/**
 * A function that wraps isInitialized so it can be used easily in different functions in Localization module. *
 * Throws an error if init-function has not been called before
 * Returns isInitialized-promise that can be awaited to make sure Localization is ready to use
 */
function checkLocalizationInit(): typeof Localization.isInitialized {
  if (!Localization.isInitialized) {
    throw Error(
      "Localization module is not initialized, yet! Call Localization.init first!"
    );
  }
  return Localization.isInitialized;
}
