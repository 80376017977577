import { QueryParams } from "@router/route-params";
import { isBetaTestingEnabledSelector } from "@store/app/app-selector";
import { useAppSelector } from "@store/store-helper";
import { Localization } from "@utils/localization";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface UseLocalization {
  /** The languages that are available for localization */
  availableLanguages: LocalizeJS.Context.LanguageObject[];

  /** The language code of the currently selected language */
  selectedLanguage: string;

  /** Function to set language by its code. Codes can be looked up in availableLanguages */
  setLanguage(languageCode: string): Promise<void>;
}

/**
 * Encapsulates logic to fetch available languages and the current language
 * Also updates state when language changes
 */
export function useLocalization(): UseLocalization {
  const [availableLanguages, setAvailableLanguages] = useState<
    LocalizeJS.Context.LanguageObject[]
  >([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    Localization.selectedLanguage
  );

  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);

  const [searchParams] = useSearchParams();

  /**
   * On initialization fetches all languages that are available for translation and store them in local state
   */
  useEffect(() => {
    async function fetchAvailableLanguages(): Promise<void> {
      const localizeLanguages = await new Promise<
        LocalizeJS.Context.LanguageObject[]
      >((resolve, reject) => {
        Localize.getAvailableLanguages((err, languages) => {
          if (err) {
            reject(err);
            return;
          }

          resolve(languages);
        });
      });

      // TODO: Remove this filter when French language is released
      // https://faro01.atlassian.net/browse/ST-2530
      // If not in beta mode, filter out the French language as it is not released yet
      const filteredLanguages = isBetaTestingEnabled
        ? localizeLanguages
        : localizeLanguages.filter((lang) => !lang.code.includes("fr"));

      setAvailableLanguages(filteredLanguages);
    }

    fetchAvailableLanguages();
  }, [isBetaTestingEnabled]);

  /**
   * Register a callback on initialization for whenever the language was changed in the
   * Localization-module. Callback sets the local language-state to the language selected
   * in the Localization-module, so the correct language is always displayed.
   */
  useEffect(() => {
    function languageChangeCallback({ to }: { to: string }): void {
      setSelectedLanguage(to);
    }

    Localization.registerSetLanguageCallback(languageChangeCallback);

    return () => {
      Localization.unregisterSetLanguageCallback(languageChangeCallback);
    };
  }, []);

  /**
   * Check if the language is set in the query parameters and if yes set it as the current language
   */
  useEffect(() => {
    if (searchParams.has(QueryParams.lang)) {
      Localization.setLanguage(
        searchParams.get(QueryParams.lang) ?? selectedLanguage
      );
    }
  }, [searchParams, selectedLanguage]);

  async function setLanguage(languageCode: string): Promise<void> {
    Localization.setLanguage(languageCode);
  }

  return { availableLanguages, selectedLanguage, setLanguage };
}
