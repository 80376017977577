import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { isApiError } from "@custom-types/type-guards";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  SphereDashboardAPITypes,
  CoreAPITypes,
  APITypes,
} from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import { BaseCoreApiClientProps, BaseEntityState } from "@store/store-types";
import { generateSdbCompanies } from "@utils/sdb-company-utils";

/**
 * State of the SdbCompany list of the user. This state contains both the workspaces and companies fetched from backend.
 * In general, SdbCompany covers both companies and admin panels (if accessible by user)
 * Therefore, this state is named SdbCompany to cover both and be distinguishable from company
 *
 * TODO: Split this file into two files, one for the slice and one for the thunks
 * https://faro01.atlassian.net/browse/ST-2127
 */
export interface SdbCompanyState extends BaseEntityState<SdbCompany> {
  /** sdbCompany (Company/Workspace) Id of the logged in user */
  selectedSdbCompanyId: string | null;

  /** List of all company-level feature states aggregated over all plans of the current company */
  selectedCompanyFeatures: CoreAPITypes.IFeatureStateResponse[];

  /** Store company context of the current company */
  selectedCompanyContext: SphereDashboardAPITypes.CompanyContext | null;

  /** Store the branding settings of the current company */
  brandingSettings: APITypes.BrandingSettings | null;

  /** Store the communication settings of the current company */
  communicationSettings: SphereDashboardAPITypes.CompanyCommunicationSettings | null;

  // TODO: Type the companySettings stronger, when the API with the json definition of settings is ready
  /** Store the company settings of the current company */
  companySettings: Record<
    SphereDashboardAPITypes.CompanySetting["identifier"],
    Omit<SphereDashboardAPITypes.CompanySetting, "identifier">
  >;

  /** Collects all the fetching properties for this slice */
  fetching: {
    /** Stores whether it is fetching all user companies */
    isFetchingSdbCompanies: boolean;

    /** Stores whether it is fetching all company features for the selected company */
    isFetchingSelectedCompanyFeatures: boolean;

    /** Stores whether it is fetching company context for the selected company */
    isFetchingSelectedCompanyContext: boolean;

    /** Stores whether it is fetching branding settings for the selected company */
    isFetchingCompanyBrandingSetting: boolean;

    /** Stores whether it is updating branding settings of the selected company */
    isUpdatingBrandingSettings: boolean;

    /** Stores whether it is fetching communication settings for the selected company */
    isFetchingCompanyCommunicationSettings: boolean;

    /** Stores whether it is updating communication settings for the selected company */
    isUpdatingCompanyCommunicationSettings: boolean;

    /** Stores whether the company settings are being fetched for the selected company */
    isFetchingCompanySettings: boolean;

    /** Stores whether the company settings are being updated for the selected company */
    isUpdatingCompanySettings: boolean;
  };
}

const NotFoundErrorCode = 404;

/** Creates an entity adapter to store a map with all the companies and workspaces that the user has access to. */
export const SdbCompaniesAdapter: EntityAdapter<SdbCompany> =
  createEntityAdapter({
    selectId: (sdbCompany) => sdbCompany.id,
  });

/**
 * Initial sdbCompany state
 */
const initialState: SdbCompanyState = {
  ...SdbCompaniesAdapter.getInitialState(),
  selectedSdbCompanyId: null,
  selectedCompanyFeatures: [],
  selectedCompanyContext: null,
  brandingSettings: null,
  communicationSettings: null,
  companySettings: {},
  fetching: {
    isFetchingSdbCompanies: false,
    isFetchingSelectedCompanyFeatures: false,
    isFetchingSelectedCompanyContext: false,
    isFetchingCompanyBrandingSetting: false,
    isUpdatingBrandingSettings: false,
    isFetchingCompanyCommunicationSettings: false,
    isUpdatingCompanyCommunicationSettings: false,
    isFetchingCompanySettings: false,
    isUpdatingCompanySettings: false,
  },
};

/**
 * Fetch list of companies of the user
 * Fetch list of workspaces of the user
 */
export const fetchCompaniesAndWorkspaces = createAsyncThunk<
  {
    companies: SphereDashboardAPITypes.ICompanyWithRoleDetails[];
    workspaces: SphereDashboardAPITypes.IWorkspace[];
  },
  BaseCoreApiClientProps
>("workspace/fetchCompaniesAndWorkspaces", async ({ coreApiClient }) => {
  try {
    const fetchedCompanies = await coreApiClient.V3.SDB.getCompanies();
    const fetchedWorkspaces =
      await coreApiClient.V3.SDB.getCurrentUsersWorkspaces();

    return {
      companies: fetchedCompanies,
      workspaces: fetchedWorkspaces?.workspaces ?? [],
    };
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/**
 * Fetches company features from the backend.
 */
export const fetchCompanyFeatures = createAsyncThunk<
  CoreAPITypes.IFeatureStateResponse[],
  BaseCoreApiClientProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/fetchCompanyFeatures",
  async ({ coreApiClient }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to fetchCompanyFeatures");
    }
    try {
      const data = await coreApiClient.V3.SDB.getCompanyFeatures(
        selectedSdbCompanyId
      );
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Fetches the branding settings of the company from the backend.
 */
export const fetchCompanyBrandingSettings = createAsyncThunk<
  APITypes.BrandingSettings,
  BaseCoreApiClientProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/fetchCompanyBrandingSettings",
  async ({ coreApiClient }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to fetchCompanyBrandingSettings");
    }
    try {
      const data = await coreApiClient.V3.SDB.getBrandingSettings(
        selectedSdbCompanyId
      );
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface UpdateCompanyBrandingSettingsProps extends BaseCoreApiClientProps {
  /** The payload for updating the company branding settings */
  payload: APITypes.BrandingSettings;
}

/**
 * Updates the branding settings of the company.
 */
export const updateCompanyBrandingSettings = createAsyncThunk<
  APITypes.BrandingSettings,
  UpdateCompanyBrandingSettingsProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/updateCompanyBrandingSettings",
  async ({ coreApiClient, payload }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error(
        "No companyId was given to updateCompanyBrandingSettings"
      );
    }
    try {
      await coreApiClient.V3.SDB.updateBrandingSettings({
        companyId: selectedSdbCompanyId,
        payload,
      });

      // Returning the payload because the response does not contain any information on the settings that were changed
      return payload;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

// TODO: Update the return type: https://faro01.atlassian.net/browse/ST-1734
/**
 * Fetches company context from the backend.
 */
export const fetchCompanyContext = createAsyncThunk<
  SphereDashboardAPITypes.CompanyContext,
  BaseCoreApiClientProps,
  {
    state: RootState;
  }
>("sdbCompany/fetchCompanyContext", async ({ coreApiClient }, { getState }) => {
  const {
    sdbCompany: { selectedSdbCompanyId },
  } = getState();

  if (!selectedSdbCompanyId) {
    throw new Error("No companyId was given to fetchCompanyFeatures");
  }
  try {
    const data = await coreApiClient.V3.SDB.getCompanyContext(
      selectedSdbCompanyId
    );
    return data;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/**
 * Fetches the communication settings of the company from the backend.
 */
export const fetchCompanyCommunicationSettings = createAsyncThunk<
  SphereDashboardAPITypes.CompanyCommunicationSettings,
  BaseCoreApiClientProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/fetchCompanyCommunicationSettings",
  async ({ coreApiClient }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to fetchCompanyBrandingSettings");
    }
    try {
      const data = await coreApiClient.V3.SDB.getCompanyCommunicationSettings(
        selectedSdbCompanyId
      );
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (arg, api) => {
      // Skip fetching if communication settings are already available in the store
      return !api.getState().sdbCompany.communicationSettings;
    },
  }
);

interface UpdateCompanyCommunicationSettingsProps
  extends BaseCoreApiClientProps {
  /** The payload for updating the company communication settings */
  payload: SphereDashboardAPITypes.CompanyCommunicationSettings;
}

/**
 * Updates the branding settings of the company.
 */
export const updateCompanyCommunicationSettings = createAsyncThunk<
  SphereDashboardAPITypes.CompanyCommunicationSettings,
  UpdateCompanyCommunicationSettingsProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/updateCompanyCommunicationSettings",
  async ({ coreApiClient, payload }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error(
        "No companyId was given to updateCompanyCommunicationSettings"
      );
    }
    try {
      await coreApiClient.V3.SDB.updateCompanyCommunicationSettings({
        companyId: selectedSdbCompanyId,
        payload,
      });

      // Returning the payload because the response does not contain any information on the settings that were changed
      return payload;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface FetchCompanySettingProps extends BaseCoreApiClientProps {
  /** The identifier of the company setting to fetch */
  identifier: SphereDashboardAPITypes.CompanySetting["identifier"];

  /**
   * The default value of the company setting that will be used
   * for creating a company setting that doesn't exist yet
   */
  defaultValue?: SphereDashboardAPITypes.CompanySetting["value"];
}

/**
 * Fetches the company setting with the given identifier from the backend.
 */
export const fetchCompanySetting = createAsyncThunk<
  SphereDashboardAPITypes.CompanySetting["value"],
  FetchCompanySettingProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/fetchCompanySetting",
  async ({ coreApiClient, identifier, defaultValue }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to fetchCompanySetting");
    }

    try {
      const data = await coreApiClient.V3.SDB.getCompanySetting({
        companyId: selectedSdbCompanyId,
        identifier,
      });
      return data;
    } catch (error) {
      // TODO: Remove this check to create a company setting if it doesn't exist, when the settings API
      // Allows to create a setting with a default value
      // https://faro01.atlassian.net/browse/ST-1981
      if (isApiError(error) && error.status === NotFoundErrorCode) {
        try {
          const data = await coreApiClient.V3.SDB.updateCompanySetting({
            companyId: selectedSdbCompanyId,
            identifier,
            payload: { value: defaultValue ?? false, modifiedAt: Date.now() },
          });
          return data;
        } catch (error) {
          throw new Error(getErrorDisplayMarkup(error));
        }
      } else {
        throw new Error(getErrorDisplayMarkup(error));
      }
    }
  }
);

interface CreateCompanySettingProps extends BaseCoreApiClientProps {
  /** The payload for creating the company setting */
  payload: Omit<SphereDashboardAPITypes.CompanySetting, "modifiedAt">;
}

export const createCompanySetting = createAsyncThunk<
  SphereDashboardAPITypes.CompanySetting,
  CreateCompanySettingProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/createCompanySetting",
  async ({ coreApiClient, payload }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to createCompanySetting");
    }

    try {
      const data = await coreApiClient.V3.SDB.updateCompanySetting({
        companyId: selectedSdbCompanyId,
        identifier: payload.identifier,
        payload: {
          value: payload.value,
        },
      });
      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface UpdateCompanySettingProps extends BaseCoreApiClientProps {
  /** The payload for updating the company communication settings */
  payload: Omit<SphereDashboardAPITypes.CompanySetting, "modifiedAt">;
}

export const updateCompanySetting = createAsyncThunk<
  SphereDashboardAPITypes.CompanySetting,
  UpdateCompanySettingProps,
  {
    state: RootState;
  }
>(
  "sdbCompany/updateCompanySetting",
  async ({ coreApiClient, payload }, { getState }) => {
    const {
      sdbCompany: { selectedSdbCompanyId, companySettings },
    } = getState();

    if (!selectedSdbCompanyId) {
      throw new Error("No companyId was given to updateCompanySetting");
    }

    try {
      const data = await coreApiClient.V3.SDB.updateCompanySetting({
        companyId: selectedSdbCompanyId,
        identifier: payload.identifier,
        payload: {
          value: payload.value,
          modifiedAt: companySettings[payload.identifier].modifiedAt,
        },
      });

      return data;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Slice to access the state of the company store module
 */
const sdbCompanySlice = createSlice({
  name: "sdbCompany",
  initialState,
  reducers: {
    /**
     * Accepts a single sdbCompany entity and adds or replaces it.
     *
     * @param state store state
     * @param entity Company to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: SdbCompaniesAdapter.setOne,
    /**
     * Accepts an array of sdbCompany entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity Companies to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: SdbCompaniesAdapter.setMany,
    /**
     * Removes all sdbCompany entities from the store.
     *
     * @param state store state
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeAll: SdbCompaniesAdapter.removeAll,
    /**
     * Accepts an array of sdbCompany IDs, and removes each sdbCompany entity with those IDs if they exist
     *
     * @param state store state
     * @param entity Company Ids to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeMany: SdbCompaniesAdapter.removeMany,
    /**
     * Accepts a single sdbCompany IDs, and removes the sdbCompany entity with that ID if it exists.
     *
     * @param state store state
     * @param entity Company Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: SdbCompaniesAdapter.removeOne,
    /**
     * Stores the selected sdbCompany Id.
     *
     * @param state store state.
     * @param action Company id to be set.
     */
    setSelectedSdbCompanyId(state, action: PayloadAction<string>) {
      state.selectedSdbCompanyId = action.payload;
    },

    /** Resets the sdb-company store to its initial state. */
    resetSdbCompanyState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompaniesAndWorkspaces.pending, (state, action) => {
        state.fetching.isFetchingSdbCompanies = true;
      })
      .addCase(fetchCompaniesAndWorkspaces.fulfilled, (state, action) => {
        state.fetching.isFetchingSdbCompanies = false;

        SdbCompaniesAdapter.setMany(
          state,
          generateSdbCompanies(
            action.payload.companies,
            action.payload.workspaces
          )
        );
      })
      .addCase(fetchCompaniesAndWorkspaces.rejected, (state, action) => {
        state.fetching.isFetchingSdbCompanies = false;
      })

      .addCase(fetchCompanyFeatures.pending, (state) => {
        state.fetching.isFetchingSelectedCompanyFeatures = true;
      })
      .addCase(fetchCompanyFeatures.fulfilled, (state, action) => {
        state.fetching.isFetchingSelectedCompanyFeatures = false;
        state.selectedCompanyFeatures = action.payload;
      })
      .addCase(fetchCompanyFeatures.rejected, (state) => {
        state.fetching.isFetchingSelectedCompanyFeatures = false;
      })

      .addCase(fetchCompanyContext.pending, (state, action) => {
        state.fetching.isFetchingSelectedCompanyContext = true;
      })
      .addCase(fetchCompanyContext.fulfilled, (state, action) => {
        state.fetching.isFetchingSelectedCompanyContext = false;
        state.selectedCompanyContext = action.payload;
      })
      .addCase(fetchCompanyContext.rejected, (state, action) => {
        state.fetching.isFetchingSelectedCompanyContext = false;
      })

      .addCase(fetchCompanyBrandingSettings.pending, (state, action) => {
        state.fetching.isFetchingCompanyBrandingSetting = true;
      })
      .addCase(fetchCompanyBrandingSettings.fulfilled, (state, action) => {
        state.fetching.isFetchingCompanyBrandingSetting = false;
        state.brandingSettings = action.payload;
      })
      .addCase(fetchCompanyBrandingSettings.rejected, (state, action) => {
        state.fetching.isFetchingCompanyBrandingSetting = false;
      })

      .addCase(updateCompanyBrandingSettings.pending, (state, action) => {
        state.fetching.isUpdatingBrandingSettings = true;
      })
      .addCase(updateCompanyBrandingSettings.fulfilled, (state, action) => {
        state.brandingSettings = action.payload;
        state.fetching.isUpdatingBrandingSettings = false;
      })
      .addCase(updateCompanyBrandingSettings.rejected, (state, action) => {
        state.fetching.isUpdatingBrandingSettings = false;
      })

      .addCase(fetchCompanyCommunicationSettings.pending, (state, action) => {
        state.fetching.isFetchingCompanyCommunicationSettings = true;
      })
      .addCase(fetchCompanyCommunicationSettings.fulfilled, (state, action) => {
        state.communicationSettings = action.payload;
        state.fetching.isFetchingCompanyCommunicationSettings = false;
      })
      .addCase(fetchCompanyCommunicationSettings.rejected, (state, action) => {
        state.fetching.isFetchingCompanyCommunicationSettings = false;
      })

      .addCase(updateCompanyCommunicationSettings.pending, (state, action) => {
        state.fetching.isUpdatingCompanyCommunicationSettings = true;
      })
      .addCase(
        updateCompanyCommunicationSettings.fulfilled,
        (state, action) => {
          state.communicationSettings = action.payload;
          state.fetching.isUpdatingCompanyCommunicationSettings = false;
        }
      )
      .addCase(updateCompanyCommunicationSettings.rejected, (state, action) => {
        state.fetching.isUpdatingCompanyCommunicationSettings = false;
      })

      .addCase(fetchCompanySetting.pending, (state) => {
        state.fetching.isFetchingCompanySettings = true;
      })
      .addCase(fetchCompanySetting.fulfilled, (state, action) => {
        state.companySettings[action.meta.arg.identifier] =
          action.payload as SphereDashboardAPITypes.CompanySetting;
        state.fetching.isFetchingCompanySettings = false;
      })
      .addCase(fetchCompanySetting.rejected, (state) => {
        state.fetching.isFetchingCompanySettings = false;
      })

      .addCase(updateCompanySetting.pending, (state) => {
        state.fetching.isUpdatingCompanySettings = true;
      })
      .addCase(updateCompanySetting.fulfilled, (state, action) => {
        state.companySettings[action.payload.identifier] = {
          value: action.payload.value,
          modifiedAt: action.payload.modifiedAt,
        };
        state.fetching.isUpdatingCompanySettings = false;
      })
      .addCase(updateCompanySetting.rejected, (state) => {
        state.fetching.isUpdatingCompanySettings = false;
      })

      .addCase(createCompanySetting.pending, (state) => {
        state.fetching.isUpdatingCompanySettings = true;
      })
      .addCase(createCompanySetting.fulfilled, (state, action) => {
        state.companySettings[action.payload.identifier] = {
          value: action.payload.value,
          modifiedAt: action.payload.modifiedAt,
        };
        state.fetching.isUpdatingCompanySettings = false;
      })
      .addCase(createCompanySetting.rejected, (state) => {
        state.fetching.isUpdatingCompanySettings = false;
      });
  },
});

export const {
  setOne,
  setMany,
  removeAll,
  removeMany,
  removeOne,
  setSelectedSdbCompanyId,
  resetSdbCompanyState,
} = sdbCompanySlice.actions;

export const sdbCompanyReducer = sdbCompanySlice.reducer;
