import { UploadedData } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { createSelector } from "@reduxjs/toolkit";
import {
  captureTreeEntityClusterPathSelector,
  captureTreeForMainRevisionSelector,
  isCaptureTreeScanEntityProcessingSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { RootState } from "@store/store-helper";
import { isScanEntity } from "@utils/capture-tree-utils";

/**
 * @returns all UploadedData entities
 */
export const uploadedDataSelector: (state: RootState) => UploadedData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const entities = captureTreeForMainRevisionSelector(state);
      const scanEntities = entities.filter(isScanEntity);

      return scanEntities.map((entity) => {
        return {
          ...entity,
          clusterPath: captureTreeEntityClusterPathSelector(entity.id)(state),
          isProcessing: isCaptureTreeScanEntityProcessingSelector(entity.id)(
            state
          ),
        };
      });
    }
  );

/**
 * @returns whether there is any UploadedData entity still processing
 */
export const hasUploadedDataProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const uploadedDataEntities = uploadedDataSelector(state);

      return uploadedDataEntities.some(
        (uploadedData) => uploadedData.isProcessing
      );
    }
  );
